import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../components/css/Courses.css";
import { Link } from "react-router-dom";
import bookmark from "../Courses/img/Bookmark.svg";
import startcard from "../Courses/img/starcard.svg";
import { useCourseContext } from "../../api/course/CourseContext";
import { useI18n } from "../../i18n/I18nContext";
import SkeletonLoader from "../../components/loader/SkeletonLoader";
import StudentIcon from "../../components/images/studentIcon";
import star from "../../components/icons/Star.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { getItem } from "../Courses/UserVideosPage/UserAdmin_id";
import { RiSpeakFill } from "react-icons/ri";

interface Props {
  searchText: string;
}

export default function SearchPage({ searchText }: Props) {
  //@ts-ignore
  const { id } = useParams();
  const { translate } = useI18n();
  const { courseApi } = useCourseContext();
  const [myCourses, setMyCourses] = useState<any[]>([]);
  const [verifyCount, setVerifyCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [courseObj, setCourseObj] = useState<any>([]);
  const [speakersObj, setSpeakersObj] = useState<any>([]);

  useEffect(() => {
    let mounted = true;
    courseApi.getVerified().then((res) => {
      if (mounted) {
        if (localStorage.getItem("myCourses")) {
          let arr = JSON.parse(localStorage.getItem("myCourses") || "{}");
          setMyCourses(arr);
        }
        setVerifyCount(res.count.toString());
      }
    });
    return () => {
      mounted = false;
    };
  }, [courseApi]);

  useEffect(() => {
    let test = localStorage.getItem("persist:auth") || "";
    let token = "";
    if (test !== null && test !== undefined) {
      token = JSON.parse(test).token;
      // console.log(JSON.parse(test).token);
    }
    let bearer = "Bearer ";
    const headers = {
      headers: {
        Authorization: (bearer += token.slice(1, -1)),
      },
      query: {
        id: Number(id),
      },
    };

    // let url = "https://inpuls-backend.backoffice.uz/api-web/get-course-search/";
    const url = `https://inpuls-backend.backoffice.uz/api-web/course-detail/?course_id=${Number(
      id
    )}`;

    setLoading(true);
    fetch(url, headers)
      .then((response) => response.json())
      .then((resDataObj) => {
        setCourseObj(resDataObj.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let test = localStorage.getItem("persist:auth") || "";
    let token = "";
    if (test !== null && test !== undefined) {
      token = JSON.parse(test).token;
      // console.log(JSON.parse(test).token);
    }
    let bearer = "Bearer ";
    const headers = {
      headers: {
        Authorization: (bearer += token.slice(1, -1)),
      },
      query: {
        id: Number(id),
      },
    };

    let url = `https://inpuls-backend.backoffice.uz/backoffice/speakers-list`;

    setLoading(true);
    fetch(url, headers)
      .then((response) => response.json())
      .then((resDataObj) => {
        // console.log("resDataObj", resDataObj);

        setSpeakersObj(resDataObj);
        // setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   let mounted = true;

  //   let test = localStorage.getItem("persist:auth");
  //   let token = "";
  //   if (test !== null && test !== undefined) {
  //     token = JSON.parse(test).token;
  //     // console.log(JSON.parse(test).token);
  //   }

  //   const headers = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //     query: {
  //       q: id,
  //     },
  //   };
  //   courseApi.searchCourse(headers).then((res) => {
  //     if (mounted) {
  //       setData(res.data_courses);
  //       let authors: any[] = [];

  //       res?.data_courses?.forEach((course: any) => {
  //         authors.push(course.author);
  //       });
  //       const getUnique = (arr: any, index: string) => {
  //         const unique = arr
  //           .map((e: any) => e[index])
  //           .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
  //           .filter((e: any) => arr[e])
  //           .map((e: any) => arr[e]);

  //         return unique;
  //       };
  //       setSpeakers(getUnique(authors, "id"));
  //     }
  //   });
  //   return () => {
  //     mounted = false;
  //   };
  // }, [courseApi, id]);

  // let test = localStorage.getItem("persist:auth");
  // if (test !== null && test !== undefined) {
  //   console.log(JSON.parse(test).token);
  // }
  // useEffect(() => {
  //   let mounted = true;
  //   if (searchText) {
  //     setLoading(true);
  //     let test = localStorage.getItem("persist:auth");
  //     let token = "";
  //     if (test !== null && test !== undefined) {
  //       token = JSON.parse(test).token;
  //       // console.log(JSON.parse(test).token);
  //     }
  //     // courseApi
  //     //   .searchCourse({
  //     //     headers: {
  //     //       Authorization: `Bearer ${token}`,
  //     //       query: {
  //     //         q: searchText,
  //     //       },
  //     //     },
  //     //   })

  //     const headers = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       query: {
  //         q: searchText,
  //       },
  //     };

  //     //https://inpuls-backend.backoffice.uz/api-web/get-course-search/?q=EMBRIOLOGIYA
  //     fetch(
  //       "https://inpuls-backend.backoffice.uz/api-web/get-course-search/",
  //       headers
  //     )
  //       .then((res) => {
  //         if (mounted) {
  //           setLoading(false);
  //           // setData(res.data_courses);
  //           let authors: any[] = [];
  //           console.log("res", 222, res);

  //           // res.data_courses.forEach((course: any) => {
  //           //   authors.push(course.author);
  //           // });
  //           const getUnique = (arr: any, index: string) => {
  //             const unique = arr
  //               .map((e: any) => e[index])
  //               .map(
  //                 (e: any, i: any, final: any) => final.indexOf(e) === i && i
  //               )
  //               .filter((e: any) => arr[e])
  //               .map((e: any) => arr[e]);

  //             return unique;
  //           };
  //           setSpeakers(getUnique(authors, "id"));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   if (searchText.length < 1) {
  //     setLoading(false);
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [searchText, courseApi]);

  return (
    <div className="search_content _main">
      <h2 className=" title_search">
        {translate("TITTLE_SEARCH_QIDIRUVNATIJASI")}
      </h2>
      <h1 className=" title_search">
        {(!!courseObj && courseObj?.modules?.length > 0) || loading
          ? translate("TITTLE_FOOTERS_KURSLAR")
          : translate("TITTLE_SEARCH_TOPILMADI")}
      </h1>
      <div className="bilimolish__2 container seacrh-block">
        <div className="bilimolish__2__slayd">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            // loop={true}
            navigation={true}
            breakpoints={{
              10: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 1,
              },
              425: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1250: {
                slidesPerView: 4,
              },
            }}
            className="mySwiper"
          >
            {!loading &&
              courseObj?.modules?.map((slayd1: any) => {
                // console.log(slayd1);
                return (
                  <SwiperSlide key={slayd1?.id}>
                    <div className="swipper__1" key={slayd1?.id}>
                      <div className="bilimolish__2__slayd__img">
                        <div className="imgbig__div">
                          <img
                            className="imgbig"
                            src={courseObj?.image}
                            alt=""
                          />
                        </div>
                        {/* <div className="imgbox">
                                                    <img
                                                        className="imgsmall"
                                                        src={`https://backend.eduon.uz/${slayd1?.author.image}`}
                                                        alt=""/>
                                                </div> */}
                      </div>

                      <div className="title">
                        <Link
                          to={"/coursesDetails/" + slayd1?.id}
                          style={{ boxShadow: "none" }}
                        >
                          <p>
                            {slayd1.title.length > 30
                              ? slayd1?.title?.substring(0, 29) + "..."
                              : slayd1?.title}
                          </p>
                        </Link>

                        {/* <img src={bookmark} alt=""/> */}
                      </div>
                      <p id="avtor">Author {courseObj?.author?.full_name}</p>

                      <div className="feedback">
                        <div className="stars">
                          <img src={star} alt="" />
                          <p>
                            {courseObj?.course_rank?.rank}
                            <span>({courseObj?.course_rank?.count})</span>
                          </p>
                        </div>
                        <div className="views">
                          <StudentIcon />
                          <p>{courseObj?.sell_count}</p>
                        </div>
                      </div>
                      <div
                        className={
                          translate("TITTLE_MAIN_BEPUL") === "Бесплатно"
                            ? "price priceRus"
                            : "price "
                        }
                      >
                        {courseObj.turi !== "Bepul" ? (
                          courseObj.price === 0 ? (
                            <p>{translate("TITTLE_MAIN_BEPUL")}</p>
                          ) : courseObj.discount <= 0 ? (
                            <h5>
                              {courseObj.price}{" "}
                              <span>{translate("TITTLE_MAIN_SOM")}</span>
                            </h5>
                          ) : (
                            <div>
                              {slayd1.discount > 0 && (
                                <h6 className="price-sale sale23">
                                  {courseObj.price}
                                  <span>{translate("TITTLE_MAIN_SOM")}</span>
                                </h6>
                              )}
                              <h5 className="price-sale">
                                {+courseObj.price - +courseObj.discount}
                                <span>{translate("TITTLE_MAIN_SOM")}</span>
                              </h5>
                            </div>
                          )
                        ) : (
                          <p>{translate("TITTLE_MAIN_BEPUL")}</p>
                        )}
                        <Link to={"/coursesDetails/" + courseObj?.id}>
                          {myCourses.find((elem) => elem === slayd1?.id) ? (
                            <a
                              href=""
                              style={{ background: "#006AFF", color: "white" }}
                            >
                              {translate("TITTLE_MAIN_BOSHLASH")}
                            </a>
                          ) : slayd1?.turi == "bepul" || slayd1?.price == 0 ? (
                            <a href="">{translate("TITTLE_MAIN_BOSHLASH")}</a>
                          ) : (
                            <a href="">{translate("TITTLE_MAIN_XARID")}</a>
                          )}
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            {loading && (
              <div className="courses_section2_cardBox">
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
              </div>
            )}
          </Swiper>
        </div>
      </div>
      <h1 className="title_search speakers_search">
        {(!!speakersObj && speakersObj.length > 0) || loading
          ? translate("TITTLE_MAIN_SPIKER")
          : translate("TITTLE_SEARCH_SPIKERTOPILMADI")}
      </h1>
      <div className="bilimolish__2 container">
        <div className="bilimolish__3__slayd ">
          <Swiper
            slidesPerView={4}
            navigation={true}
            // loop={true}
            breakpoints={{
              10: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 1,
              },
              425: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1250: {
                slidesPerView: 4,
              },
            }}
          >
            {!!speakersObj &&
              speakersObj?.results?.map((x: any) => {
                //speakersObj?.results

                return (
                  <SwiperSlide key={x?.id}>
                    <div className="slayder__3" key={x?.id}>
                      {x?.image === null ? (
                        <RiSpeakFill size={72} />
                      ) : (
                        <img
                          className="slayd__3__img"
                          src={`https://inpuls-backend.backoffice.uz/${x?.image}`}
                          alt="Speaker_image"
                        />
                      )}

                      <h1>
                        {x?.name.split(" ")[0]}{" "}
                        {x?.name.split(" ")[1].substring(0, 1)}.
                      </h1>
                      <h2>
                        {x?.type?.length > 15
                          ? x?.type.substring(0, 15) + "."
                          : x?.type}
                      </h2>
                      <h2>{x?.compony ? x?.compony : "-"}</h2>
                      <div className="stars">
                        <img src={star} alt="" />
                        <p>
                          {x?.rating?.rank}
                          <span>({x?.rating?.count})</span>
                        </p>
                      </div>
                      <Link to={`/speaker/${x?.id}`}>
                        <a href="">{translate("TITTLE_MAIN_PROFILS")}</a>
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
            {loading && (
              <div className="courses_section2_cardBox">
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
                <SkeletonLoader classname={"cours_sec2_cardBlock"} />
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
