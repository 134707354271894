import React, { useState, useEffect, useLayoutEffect } from "react";
import ConverHours from "./ConverHours";
import { useDispatch } from "react-redux";
import {
  durationSelector,
  hoursSelector,
  minutesSelector,
  secondsSelector,
  setHoursApp,
  setMinutesApp,
  setSecondsApp,
} from "../../reducers/appReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { useHistory } from "react-router";

export default function TimerNew() {
  const dispatch = useDispatch();
  const history = useHistory();
  const minutesApp = useShallowEqualSelector(minutesSelector);
  const hoursApp = useShallowEqualSelector(hoursSelector);
  const secondsApp = useShallowEqualSelector(secondsSelector);
  const durationApp = useShallowEqualSelector(durationSelector);
  const { formatedHours, formatedMinutes } = ConverHours(durationApp);
  const [hours, setHours] = useState(hoursApp ?? formatedHours);
  const [minutes, setMinutes] = useState(
    minutesApp ? minutesApp : formatedMinutes
  );
  const [seconds, setSeconds] = useState(secondsApp ? secondsApp : 0);
  const [time, setTime] = useState("00:00:00");
  useEffect(() => {
    if (seconds) {
      dispatch(setSecondsApp({ seconds: seconds }));
    }
  }, [seconds]);
  useEffect(() => {
    if (minutes) {
      dispatch(setMinutesApp({ minutes: minutes }));
    }
  }, [minutes]);
  useEffect(() => {
    // if (hours) {
    dispatch(setHoursApp({ hours: hours }));
    // }
  }, [hours]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(timer);
            dispatch(setMinutesApp({ minutes: 0 }));
          } else {
            setHours((prev) => prev - 1);
            dispatch(setHoursApp({ hours: hours }));
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes((prev) => prev - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hours, minutes, seconds]);
  useEffect(() => {
    const newTime = convertTimeToString(hours, minutes, seconds);
    setTime(newTime);
  }, [hours, minutes, seconds]);
  function convertTimeToString(hours: any, minutes: any, seconds: any) {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  // console.log(time, "time")
  return <span>{time}</span>;
}
