import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import "./ParolUnut_1.css"
import {Formik} from "formik";
import {InputField} from "../../../components/forms/InputField";
import {useAuthContext} from "../../../api/auth/authContext";
import {Routes} from "../../../constants/Routes";
import {useHistory} from "react-router";
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'

interface Props {
    readonly switchUser: boolean;
    readonly setSwitchUser: Dispatch<SetStateAction<boolean>>;
}


export default function ParolUnut_1({switchUser, setSwitchUser}: Props) {
    const {authApi} = useAuthContext();
    const [phone , setPhone] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();
    const submitHandler = useCallback(() => {
        let phoneNumber = phone;
        phoneNumber = phoneNumber.length === 9 ? "998" + phoneNumber : phoneNumber.includes("+") ? phoneNumber.replace("+", "") : phoneNumber;
        phoneNumber = phoneNumber.substr(0, 25);
        localStorage.setItem("phoneUser", phoneNumber);
        authApi.loginCredentials({
            query: {
                phone: phoneNumber,
                type: "resset_password"
            }
        }).then((res) => {
                if (res.success === true) {
                    history.push({pathname: Routes.ForgotPassword2})
                } else {
                    setError(res.message)
                }
            }
        )
    }, [authApi, history, phone]);
    const submitHandlerEmail = useCallback(() => {
        localStorage.setItem("phoneUser", phone);
        authApi.loginCredentials({
            query: {
                phone: phone,
                type: "resset_password"
            }
        }).then((res) => {
                if (res.success === true) {
                    history.push({pathname: Routes.ForgotPassword2})
                } else {
                    setError(res.message)
                }
            }
        )
    }, [authApi, history, phone]);
    return (
        <div className="ParolUnut_1_block _main">
            <div className="LoginPage-inner">
                <div className="login-courses_sec_btn ">
                    <h2 className={switchUser ? 'course_btn_active' : 'course_btn_pass'}> Telefon orqali</h2>
                    <h3 className={`${switchUser ? 'course_btn_active' : 'course_btn_pass'} Bilim-oluvchi`}>Telefon</h3>
                    <button onClick={() => {
                        setSwitchUser(prev => !prev);
                    }}
                            className={switchUser ? 'course_btn_1' : 'course_btn_2'}
                            type="button">
                        <div></div>
                    </button>
                    <h2 className={switchUser ? 'course_btn_pass' : 'course_btn_active'}>  Email orqali</h2>
                    <h3 className={`${switchUser ? 'course_btn_pass' : 'course_btn_active'} Bilim-oluvchi`}>Email</h3>
                </div>
            </div>
            <div className="ParolUnut_1 ">
                <h4 style={{marginBottom: "50px"}}>PAROLNI TIKLASH</h4>
                <div className="ParolUnut_1_block_input">
                    {/*<div className='ParolUnut_1_div1'>*/}
                    {/*    <select>*/}
                    {/*        <option>+998 ( UZ )</option>*/}
                    {/*        <option>+1 ( USA )</option>*/}
                    {/*        <option>+7 ( RUS )</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className='ParolUnut_1_div2 mb-2'>
                        {switchUser ? <PhoneInput
                            containerClass="signUpPage-inner-item phone-flag"
                            country={'uz'}
                            // value={phone}
                            masks={{uz: '(..) ...-..-..'}}
                            onChange={(e) => setPhone(e)}
                        /> :
                            <input
                                className="loginPage-inner-item email-input"
                                type="email"
                                placeholder="Emailni kiriting"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        }
                    </div>
                </div>
                {error && <p className="forget-error">{error}</p>}

                <div className='ParolUnut_1_tasdiq'>
                    <p onClick={() => history.goBack()}>orqaga</p>
                    <button type="submit" onClick={switchUser ? submitHandler : submitHandlerEmail}>Tasdiqlash</button>
                </div>
            </div>
        </div>
    );
}
