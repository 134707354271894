import { useHistory } from 'react-router';
import Loader from '../../components/loader/Loader';
import { useShallowEqualSelector } from '../../hooks/useShallowSelector';
import { finalTestSelector } from '../../reducers/appReducer';
import Footer from '../Footer';
import Navbar from '../Navbar';
import InCorrectImg from './NoTasdiq.svg';
import Stucture from './Stucture';
import Stucture1 from './Stucture1';
import Stucture2 from './Stucture2';
import CorrectImg from './Tasdiq.svg';
import './test.scss';

function FinalTestResults() {
	const history = useHistory();
	const finalTestResults = useShallowEqualSelector(finalTestSelector);

	if (!finalTestResults) {
		return <Loader />;
	}

	return (
		<>
			<Navbar nextClick={() => {}} prevClick={() => {}} />
			<div className='test-page'>
				<div className='test-page-container'>
					<div>
						<span
							style={{
								fontSize: '15px',
								background: '#FFFFFF',
								// color: "black",
								padding: '10px',
								cursor: 'pointer',
								backgroundColor: '#0100fe',
								color: '#FFF',
								borderRadius: '4px',
							}}
							onClick={() => history.goBack()}
						>
							Videolarga qaytish
						</span>
					</div>
					<div className='main-results'>
						<span>Natijalar</span>
						<div className='results'>
							{finalTestResults?.takers?.answers
								?.reverse()
								?.map((x: any, index: number) => (
									<div className='result-con' key={index}>
										<h3>{index + 1}</h3>
										<img
											src={
												x?.is_correct
													? CorrectImg
													: InCorrectImg
											}
											alt=''
										/>
									</div>
								))}
						</div>
						<div className='desc'>
							<span>Savol , Javob , Izoh</span>
							{finalTestResults?.questions?.map(
								(q: any, idx: number) => {
									let userCurrentAnswer =
										finalTestResults?.takers?.answers?.find(
											(item: any, idx: number) => {
												if (
													item.question === q.id
												) {
													return q?.answers?.find(
														(
															answerObj: any
														) => {
															if (
																answerObj.title ===
																	item.answers &&
																!answerObj.is_correct
															) {
																return item;
															}
														}
													);
												}
											}
										);

									// console.log(userCurrentAnswer);

									return (
										<div className='desc-body' key={q.id}>
											<div className='ques'>
												<div>
													<span>
														{idx + 1}&nbsp;
													</span>
													<span className='text-m'>
														Savol:
													</span>
												</div>
												<div>
													{' '}
													{q.structure === 1 ? (
														<Stucture
															img={
																q?.img1
															}
															title={
																q?.title
															}
														/>
													) : q.structure ===
													  2 ? (
														<Stucture1
															img={
																q?.img1
															}
															title={
																q?.title
															}
														/>
													) : q.structure ===
													  3 ? (
														<Stucture2
															isImg={
																!(
																	q
																		?.title
																		?.length >
																	0
																)
															}
															img={
																q?.img1
															}
															title={
																q?.title
															}
														/>
													) : null}
												</div>
											</div>
											{q?.answers?.map(
												(l: any, idx: number) => {
													// console.log(l);
													// let test = finalTestResults?.takers?.answers?.forEach(
													//   (item: any, idx: number) => console.log(idx, item)
													// );

													// userCurrentAnswer.title === l.title
													//
													// console.log(userCurrentAnswer !== undefined ? "red" : "black");
													// console.log(
													//   userCurrentAnswer !== undefined &&
													//     userCurrentAnswer?.answers === l.title
													//     ? "red"
													//     : ""
													// );

													return (
														<div key={idx}>
															<div className='ans'>
																<span className='text-m'>
																	<p
																		// className={"text-m"}
																		style={{
																			backgroundColor:
																				l?.is_correct
																					? 'green'
																					: userCurrentAnswer !==
																							undefined &&
																					  userCurrentAnswer?.answers ===
																							l?.title
																					? 'red'
																					: '',
																			color: l.is_correct
																				? 'white'
																				: userCurrentAnswer !==
																						undefined &&
																				  userCurrentAnswer?.answers ===
																						l?.title
																				? 'white'
																				: '',
																			padding: '1rem 10px',
																			fontWeight: 600,
																		}}
																	>
																		{l.is_correct
																			? 'Javob:'
																			: 'Variant:'}
																		<em
																			className='text-o'
																			style={{
																				fontSize: '13px',
																			}}
																		>
																			{
																				l.title
																			}
																		</em>
																	</p>
																</span>
															</div>
															<>
																{l.structure ===
																1 ? (
																	<div className='desc-desc'>
																		<span className='text-m'>
																			Izoh:
																		</span>

																		<Stucture
																			img={
																				l?.img1
																			}
																			title={
																				l?.title
																			}
																		/>
																	</div>
																) : null}
															</>
														</div>
													);
												}
											)}
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
			<Footer timer={true} />
		</>
	);
}

export default FinalTestResults;
