import { useCallback, useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { AuthApi } from "./AuthApi";
import { useDispatch } from "react-redux";
import { clearUser } from "../../reducers/userReducer";
import { resetToken } from "../../reducers/authReducer";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { Routes } from "../../constants/Routes";

interface Props {
  readonly authApi: AuthApi;
  readonly logout: (closeModal?: any) => void;
}

export function useAuthContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookie = new Cookies();
  const history = useHistory();
  const api = useMemo(() => new AuthApi(data), [data]);

  const logoutHandler = useCallback(
    (closeModal = () => {}) => {
      localStorage.removeItem("myCourses");
      dispatch(clearUser());
      dispatch(resetToken());
      cookie.remove("token", { path: "/" });
      cookie.remove("refreshToken", { path: "/" });
      history.push(Routes.LoginPage);
      closeModal();
    },
    [dispatch, history, cookie]
  );

  return {
    authApi: api,

    logout: logoutHandler,
  };
}
