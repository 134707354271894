import { Route, Switch, useParams } from "react-router";
import React, { Suspense, useEffect, useState } from "react";
import NotFoundContainer from "./NotFoundContainer";
import { Routes } from "../constants/Routes";
import { Redirect } from "react-router-dom";
import "../components/css/main.css";
import "../components/css/main2.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import SearchPage from "../pages/SearchPage/SearchPage";
import AuthContainer from "./AuthContainer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { tokenSelector } from "../reducers/authReducer";
import { useAuthContext } from "../api/auth/authContext";
import { userSelector } from "../reducers/userReducer";
import LoaderNew from "../components/loader/LoaderNew";
import FileReader from "../pages/Courses/UserVideosPage/FileReader";
import PdfReader from "../pages/Courses/UserVideosPage/PdfReader";
import {
  fileUrlSelector,
  pdfUrlSelector,
  setHoursApp,
  setMinutesApp,
  setSecondsApp,
  setTestPage,
} from "../reducers/appReducer";
import FinalTestResults from "../test/Tests/FinalTestResults";
import { useDispatch } from "react-redux";
import ShowReadingMaterial from "../pages/Courses/ShowReadingMaterial/ShowReadingMaterial";

const Courses = React.lazy(() => import("../pages/Courses/Courses"));

const TestFile = React.lazy(() => import("../test/Tests"));

const UserProfile = React.lazy(
  () => import("../pages/user_profile/userAdmin/UserAdmin")
);
const CourseItem = React.lazy(() => import("../pages/Courses/[id]"));
const CourseWatchVideo = React.lazy(
  () => import("../pages/Courses/UserVideosPage/UserAdmin_id")
);
const SpeakerPage = React.lazy(
  () => import("../pages/Courses/SingleCoursePage/CoursesSinglePage")
);
const Payment = React.lazy(() => import("../pages/payment/[id]"));

export function RootContainer() {
  const [switchUser, setSwitchUser] = useState(true);
  const [searchText, setSearchText] = useState("");
  const token = useShallowEqualSelector(tokenSelector);
  const dispatch = useDispatch();
  const user = useShallowEqualSelector(userSelector);
  const pdfUrl = useShallowEqualSelector(pdfUrlSelector);
  const fileUrl = useShallowEqualSelector(fileUrlSelector);

  const { logout } = useAuthContext();
  useEffect(() => {
    if (!token && logout) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!window.location.pathname.includes("/test/")) {
      dispatch(setHoursApp({ hours: undefined }));
      dispatch(setMinutesApp({ minutes: undefined }));
      dispatch(setSecondsApp({ seconds: undefined }));
      dispatch(setTestPage({ page: 1 }));
    }
  }, [window.location.pathname]);

  return (
    <Suspense
      fallback={
        <div className="preloader">
          <LoaderNew />
        </div>
      }
    >
      <div className="wrapper">
        <Navbar setSearchText={setSearchText} searchText={searchText} />
        {/* {window.location.pathname === '/test' && 'd-none'} */}
        {window.localStorage.getItem("vU") !== "1" ? (
          <></>
        ) : (
          <>
            {window.localStorage.getItem("vP") === undefined &&
            token !== undefined &&
            user !== undefined ? (
              <>
                {/*{closeAlertModal && <ModalAlert onClose={setCloseAlertModal} />}*/}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Switch>
          {!token && <Redirect exact={true} to={Routes.LoginPage} from="/" />}
          {token && <Redirect exact={true} to={Routes.Courses} from="/" />}
          <Route
            path={Routes.Courses}
            render={() => (
              <Courses setSwitchUser={setSwitchUser} switchUser={switchUser} />
            )}
          />
          <Route
            path={Routes.AuthPage}
            render={() => (
              <AuthContainer
                switchUser={switchUser}
                setSwitchUser={setSwitchUser}
              />
            )}
          />
          {/* test  */}
          <Route path={Routes.TestFile} component={TestFile} />
          <Route path={Routes.TestResult} component={FinalTestResults} />

          <Route path={Routes.UserProfile} component={UserProfile} />
          <Route path={Routes.CoursesById} component={CourseItem} />
          <Route path={Routes.VideosById} component={CourseWatchVideo} />
          <Route path={Routes.SpeakerId} component={SpeakerPage} />
          <Route path={Routes.Payment} component={Payment} />
          <Route
            path={Routes.Search}
            render={() => <SearchPage searchText={searchText} />}
          />

          <Route
            path={`/pdf-reading-material`}
            render={() => <ShowReadingMaterial />}
          />

          <Route component={NotFoundContainer} />
        </Switch>

        {!window.location.pathname.includes(`auth`) && (
          <Footer switchUser={switchUser} setSwitchUser={setSwitchUser} />
        )}

        {fileUrl.length > 0 && <FileReader fileUrl={fileUrl} />}
        {/* {pdfUrl.length > 0 && <PdfReader fileUrl={pdfUrl} />} */}
      </div>
    </Suspense>
  );
}
