import React from "react";

interface Props {
  readonly img: string;
  readonly title: string;
}

function Stucture({ title, img }: Props) {
  return (
    <div className="stc1">
      {img ? (
        <img
          src={img}
          alt=""
          // width={800}
          height={400}
          className="image-main-full"
        />
      ) : (
        ""
      )}
      <h1 className="w-100 mt-2" style={{ fontSize: "14px" }}>
        {title}
      </h1>
    </div>
  );
}

export default Stucture;
