import React from "react";

interface Props {
  readonly img: string;
  readonly title: string;
  readonly isImg: boolean;
}

function Stucture2({ title, img, isImg }: Props) {
  return (
    <div className="stc2">
      {isImg ? (
        <img
          src={img}
          alt=""
          // width={800}
          height={200}
          className="image-main-full"
        />
      ) : (
        <span className="w-100 mt-2" style={{ fontSize: "22px" }}>
          {title}
        </span>
      )}
    </div>
  );
}

export default Stucture2;
