import { update } from "immupdate";
import { PersistConfig } from "redux-persist";
import {AppLanguage, getDefaultLanguage} from "../i18n/I18nContext";
import {createReducer, createRootReducer, PerformAction} from "../utils/ReducerUtils";
import {AppStoreState} from "../store/RootReducer";


export const appReducerPersistConfig: Partial<PersistConfig<AppReducerState>> = {
    whitelist: ["version", "language", "pdfUrl", "fileUrl", "minutes", "hours", "seconds", "page", "duration", "finalTest"],
};

interface SwitchLanguageMeta {
    readonly language: AppLanguage;
}
interface SetPdfUrlMeta {
    readonly pdfUrl: string;
}
interface SetFileUrlMeta {
    readonly fileUrl: string;
}
interface SetFinalTestMeta {
    readonly finalTest: any;
}
interface SetHoursMeta {
    readonly hours: number | undefined;
}
interface SetPageTestMeta {
    readonly page: number;
}
interface SetMinutesMeta {
    readonly minutes: number | undefined;
}
interface SetDurationMeta {
    readonly duration: number;
}
interface SetSecondsMeta {
    readonly seconds: number | undefined;
}
enum ReducerActions {
    SwitchLanguage = "App/SwitchLanguage",
    SetPdfURl = "App/SetPdfUrl",
    SetFileURl = "App/SetFileUrl",
    SetHours = "App/SetHours",
    SetMinutes = "App/SetMinutes",
    SetSeconds = "App/SetSeconds",
    SetDuration = "App/SetDuration",
    SetPageTest = "App/SetPageTest",
    SetFinalTest = "App/SetFinalTest"
}

export interface AppReducerState {
    readonly language: AppLanguage;
    readonly pdfUrl: string;
    readonly fileUrl: string;
    readonly hours?: number | undefined;
    readonly minutes?: number | undefined;
    readonly seconds?: number | undefined;
    readonly duration: number;
    readonly page?: number;
    readonly finalTest?: any;
}

function getState(): AppReducerState {
    return {
        language: getDefaultLanguage(),
        pdfUrl: "",
        fileUrl: "",
        duration: 0,
    };
}

export const appReducer = createRootReducer<AppReducerState>(
    getState(),

    createReducer([ReducerActions.SwitchLanguage], (state, { meta }) =>
        update(state, { language: meta.language }),
    ),
    createReducer([ReducerActions.SetFileURl], (state, { meta }) =>
        update(state, { fileUrl: meta.fileUrl }),
    ),
    createReducer([ReducerActions.SetPdfURl], (state, { meta }) =>
        update(state, { pdfUrl: meta.pdfUrl }),
    ),
    createReducer([ReducerActions.SetHours], (state, { meta }) =>
        update(state, { hours: meta.hours }),
    ),
    createReducer([ReducerActions.SetMinutes], (state, { meta }) =>
        update(state, { minutes: meta.minutes }),
    ),
    createReducer([ReducerActions.SetSeconds], (state, { meta }) =>
        update(state, { seconds: meta.seconds }),
    ),
    createReducer([ReducerActions.SetDuration], (state, { meta }) =>
        update(state, { duration: meta.duration }),
    ),
    createReducer([ReducerActions.SetPageTest], (state, { meta }) =>
        update(state, { page: meta.page }),
    ),
    createReducer([ReducerActions.SetFinalTest], (state, { meta }) =>
        update(state, { finalTest: meta.finalTest }),
    ),

);

// ==================
// Selectors
// ==================

export const appLanguageSelector = ({ app }: AppStoreState): AppLanguage => app.language;

export const pdfUrlSelector = ({ app }: AppStoreState): string => app.pdfUrl;

export const fileUrlSelector = ({ app }: AppStoreState): string => app.fileUrl;

export const hoursSelector = ({ app }: AppStoreState): number | undefined => app.hours;

export const minutesSelector = ({ app }: AppStoreState): number | undefined => app.minutes;

export const secondsSelector = ({ app }: AppStoreState): number | undefined => app.seconds;

export const durationSelector = ({ app }: AppStoreState): number => app.duration;

export const pageTestSelector = ({ app }: AppStoreState): number | undefined => app.page;

export const finalTestSelector = ({ app }: AppStoreState): any => app.finalTest;


// ==================
// Actions
// ==================

export function switchLanguage(meta: SwitchLanguageMeta): PerformAction<SwitchLanguageMeta> {
    return { type: ReducerActions.SwitchLanguage, meta };
}
export function setPdfUrl(meta: SetPdfUrlMeta): PerformAction<SetPdfUrlMeta> {
    return { type: ReducerActions.SetPdfURl, meta };
}

export function setFileUrl(meta: SetFileUrlMeta): PerformAction<SetFileUrlMeta> {
    return { type: ReducerActions.SetFileURl, meta };
}
export function setHoursApp(meta: SetHoursMeta): PerformAction<SetHoursMeta>{
    return { type: ReducerActions.SetHours, meta }
}
export function setMinutesApp(meta: SetMinutesMeta): PerformAction<SetMinutesMeta>{
    return { type: ReducerActions.SetMinutes, meta }
}
export function setSecondsApp(meta: SetSecondsMeta): PerformAction<SetSecondsMeta>{
    return { type: ReducerActions.SetSeconds, meta }
}

export function setDurationApp(meta: SetDurationMeta): PerformAction<SetDurationMeta>{
    return { type: ReducerActions.SetDuration, meta }
}
export function setTestPage(meta: SetPageTestMeta): PerformAction<SetPageTestMeta>{
    return { type: ReducerActions.SetPageTest, meta }
}
export function setFinalTest(meta: SetFinalTestMeta): PerformAction<SetFinalTestMeta>{
    return { type: ReducerActions.SetFinalTest, meta }
}


