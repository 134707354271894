import React, { useCallback, useEffect, useState } from "react";
import "../../components/css/SignUpPage3.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { InputField } from "../../components/forms/InputField";
import { Routes } from "../../constants/Routes";
import { useAuthContext } from "../../api/auth/authContext";
import axios from "axios";
import { toast } from "react-toastify";

const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
};

interface Props {
  readonly switchUser: boolean;
}

export default function SignUpPage3({ switchUser }: Props) {
  const { authApi } = useAuthContext();
  const location: any = useLocation();
  const history = useHistory();
  const [phone, setPhone] = useState<string | any>("");
  const [code, setCode] = useState<string | any>("");
  const [groups, setGroups] = useState<any>([]);
  const [check, setCheck] = useState(false);
  const [error, setError] = useState("");
  const [gender, setGernder] = useState("");
  const [groupId, setGroupId] = useState("");
  const [faculty, setFaculty] = useState("");
  const [university, setUniversity] = useState("");
  const [universityData, setUniversityData] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [course, setCourse] = useState("");
  const [ref, setRef] = useState<any | string>("");
  useEffect(() => {
    setPhone(window.localStorage.getItem("phoneUser"));
    setCode(window.localStorage.getItem("code"));
    setRef(window.localStorage.getItem("ref"));
  }, [switchUser]);

  const submitHandler = useCallback(
    (value) => {
      // console.log(value);

      if (
        value.password !== "" &&
        value.last_name !== "" &&
        groupId.length > 0 &&
        value.first_name !== "" &&
        gender !== "" &&
        value.password === value.confirmPassword &&
        check
      ) {
        authApi
          .fullRegister({
            data: {
              first_name: value.first_name.replace(/\s/g, ""),
              last_name: value.last_name.replace(/\s/g, ""),
              password: value.password.replace(/\s/g, ""),
              gender: gender.replace(/\s/g, ""),
              phone: phone.replace(/\s/g, ""),
              code: code.replace(/\s/g, ""),
              ref_code: null,
              parent_ref_code: ref,
              group: groupId,
              faculty: faculty,
              kurs: course,
              university: university,
            },
          })
          .then((res) => {
            // console.log(`res`, res);
            if (res.success === true) {
              history.push({
                pathname: "/auth/login",
                state: { oldUrl: location.state?.oldUrl },
              });
              toast.success("Muvaffaqiyatli royhatdan o'tdingiz", {
                position: toast.POSITION.TOP_RIGHT,
              });
              window.localStorage.removeItem("ref");
            } else if (res.success === false && res.error !== "") {
              if (res.error === "UNIQUE constraint failed: users_users.phone") {
                toast.error(
                  "This phone number already registered, Try to login",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
              } else {
                toast.error(res.error, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        value.password !== value.confirmPassword
          ? setError(
              "Kirilgan parollar mos kelmayapti, iltimos tekshirib qaytadan urinib ko'ring"
            )
          : !check
          ? setError("Iltimos foydalanish shartlari bilan tanishing")
          : setError(
              "Barcha maydonlar tuldirilishi shart, iltimos , qaytadan urinib koring"
            );
      }
    },
    [authApi, history, phone, code, gender, check, ref]
  );
  const submitHandlerEmail = useCallback(
    (value) => {
      if (
        value.password !== "" &&
        value.last_name !== "" &&
        value.first_name !== "" &&
        groupId.length > 0 &&
        gender !== "" &&
        value.password === value.confirmPassword &&
        check
      ) {
        authApi
          .fullRegister({
            data: {
              first_name: value.first_name.replace(/\s/g, ""),
              last_name: value.last_name.replace(/\s/g, ""),
              password: value.password.replace(/\s/g, ""),
              gender: gender.replace(/\s/g, ""),
              email: phone,
              code: code.replace(/\s/g, ""),
              ref_code: null,
              parent_ref_code: ref,
              group: groupId,
            },
          })
          .then((res) => {
            if (res.success === true) {
              history.push({
                pathname: "/auth/login",
                state: {
                  oldUrl: location.state?.oldUrl,
                },
              });

              window.localStorage.removeItem("ref");
              toast.success("Muvaffaqiyatli royhatdan o'tdingiz", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        value.password !== value.confirmPassword
          ? setError(
              "Kirilgan parollar mos kelmayapti, iltimos tekshirib qaytadan urinib ko'ring"
            )
          : !check
          ? setError("Iltimos foydalanish shartlari bilan tanishing")
          : setError(
              "Barcha maydonlar tuldirilishi shart, iltimos , qaytadan urinib koring"
            );
      }
    },
    [authApi, history, phone, code, gender, check, ref, groupId]
  );
  useEffect(() => {
    if (course)
      authApi.getGroups(course).then((res) => {
        setGroups(res);
      });
  }, [course]);
  useEffect(() => {
    authApi.getUniversity().then((res) => {
      setUniversityData(res);
    });
  }, []);
  useEffect(() => {
    if (university) {
      authApi.getFaculty(university).then((res) => {
        setFacultyData(res);
      });
    }
  }, [university]);
  useEffect(() => {
    if (faculty) {
      authApi.getKurs(faculty).then((res) => {
        console.log("getKurs", res);
        setCourseData(res);
      });
    }
  }, [faculty]);
  return (
    <>
      <section className="sectionSignUpPage3">
        <div className="container">
          <Formik
            onSubmit={(values) =>
              switchUser ? submitHandler(values) : submitHandlerEmail(values)
            }
            initialValues={INITIAL_VALUES}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="mainSignUpPage3">
                  <div className="signUpPage-input-item3">
                    <InputField
                      inputClassName="signUpPage-inner-item3"
                      placeholder="Ismingiz"
                      editable={true}
                      type="text"
                      name="first_name"
                    />
                    <InputField
                      inputClassName="signUpPage-inner-item3"
                      placeholder="Familiyangiz"
                      type="text"
                      name="last_name"
                    />
                    <InputField
                      inputClassName="signUpPage-inner-item3"
                      placeholder="Parol"
                      type="text"
                      name="password"
                    />
                    <InputField
                      inputClassName="signUpPage-inner-item3"
                      placeholder="Parolni tasdiqlang"
                      type="text"
                      name="confirmPassword"
                    />

                    <label className="SignUpPage3_label">
                      Erkak
                      <input
                        name="radio"
                        type="radio"
                        value="Erkak"
                        id="radio"
                        onChange={(e) => setGernder(e.target.value)}
                      />
                    </label>

                    <label className="SignUpPage3_label">
                      Ayol
                      <input
                        name="radio"
                        type="radio"
                        value="Ayol"
                        id="radio"
                        onChange={(e) => setGernder(e.target.value)}
                      />
                    </label>
                    <div className="options">
                      <select
                        className="options-sig"
                        name="cars"
                        onChange={(e) => setUniversity(e.target.value)}
                      >
                        <option disabled selected hidden>
                          University
                        </option>
                        {universityData &&
                          universityData?.map((x: any) => {
                            return (
                              <option key={x.id} value={x.id}>
                                {x?.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {facultyData?.length > 0 && (
                      <div className="options">
                        <select
                          className="options-sig"
                          name="cars"
                          onChange={(e) => setFaculty(e.target.value)}
                        >
                          <option disabled selected hidden>
                            Faculty
                          </option>
                          {facultyData &&
                            facultyData.map((x: any) => {
                              return (
                                <option key={x.id} value={x.id}>
                                  {x?.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                    {courseData?.length > 0 && (
                      <div className="options">
                        <select
                          className="options-sig"
                          name="cars"
                          onChange={(e) => setCourse(e.target.value)}
                        >
                          <option disabled selected hidden>
                            Kurs
                          </option>
                          {courseData &&
                            courseData?.map((x: any) => {
                              return (
                                <option key={x.id} value={x.id}>
                                  {x?.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                    {groups?.length > 0 && (
                      <div className="options">
                        <select
                          className="options-sig"
                          name="cars"
                          onChange={(e) => setGroupId(e.target.value)}
                        >
                          <option disabled selected hidden>
                            Guruh
                          </option>
                          {groups &&
                            groups.map((x: any) => {
                              return (
                                <option key={x.id} value={x.id}>
                                  {x?.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="signUpPage-text-link-chak">
                    <Link to={Routes.Conditions}>Foydalanish shartlari </Link>
                    <label>
                      <p className="signUpPage-text-p">bilan tanishdim</p>
                    </label>
                    <div className="checkbox">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          name="color-1"
                          value="indigo"
                          onClick={() => setCheck((prevState) => !prevState)}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                  <div className="singUpPage-inner-btn-item3">
                    {error && (
                      <div className="error-text-full-reg">{error}</div>
                    )}
                    <button className="signUpPage-btn-inner3" type="submit">
                      Davom etish
                    </button>
                    <p className="signUpPage-text-item3">
                      Akkauntingiz bormi? unda{" "}
                      <Link to={Routes.AuthPage}>bu yerga</Link> bosing
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
