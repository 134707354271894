export enum Routes {
  MainPage = "/main",
  Courses = "/courses",
  Faq = "/faq",
  About = "/about",
  Conditions = "/conditions",
  EduBranding = "/edu-branding",
  Ramadan = "/ramadan",
  // Tast
  TestFile = "/test",
  TestResult = "/result",
  // Auth and Login
  AuthPage = "/auth",
  LoginPage = "/auth/login",
  SignUp1 = "/auth/sign-up",
  SignUp2 = "/auth/sign-up-verify",
  SignUp3 = "/auth/sign-up-full-register",
  ForgotPassword1 = "/auth/forgot-password-phone",
  ForgotPassword2 = "/auth/forgot-password-code-verify",
  ForgotPassword3 = "/auth/forgot-password-new-password",

  //User Profile
  UserProfile = "/user-profile/",
  UserProfileCourses = "/user-profile/courses",
  UserProfileBalance = "/user-profile/balance",
  UserProfileProfile = "/user-profile/profile",
  UserProfileId = "/user-profile/id",
  CoursesById = "/coursesDetails/:id",
  VideosById = "/course-videos/:id",
  SpeakerId = "/speaker/:id",

  //Payment
  Payment = "/payment/:id",

  //Search
  Search = "/search/:id",

  //Pdf
  Pdf = "/pdf",
}
///user-profile/profile
