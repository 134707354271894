import { I18nSchema } from "./I18nSchema";
import { I18nRussian } from "./I18nRussian";

export const I18nUzbek: I18nSchema = {
  ...I18nRussian,

  // Navbar
  NAVBAR_MAIN_PAGE_TITTLE: "Bosh sahifa",
  NAVBAR_COURSES_TITTLE: "Kurslar",
  NAVBAR_FAQ_TITTLE: "FAQ",
  NAVBAR_ABOUT_TITTLE: "Biz haqimizda",
  NAVBAR_EDUBRANDING_TITTLE: "Edu-branding",
  NAVBAR_KABINET_TITLE: "Shaxsiy kabinet",
  NAVBAR_BILIMBERISH_TITLE: "Bilim ulashish",
  NAVBAR_CHIQISH_TITLE: "Chiqish",
  NAVBAR_QIDIRUV_TITLE: "Kurslar bo'ylab qidiruv",

  // ALERT
  ALERT_ASSALOMUALAYKUM: "Assalomu alaykum",
  ALERT_ROYXATDANOTING: "Hoziroq ro'yxatdan o'ting va",
  ALERT_VAUCHERGAEGABOLING: "50.000 so'm vaucherga ega bo'ling",
  ALERT_ROYXATDANOTISH: "Ro'yxatdan o'tish",
  ALERT_MALUMOTTOLDIRISH: "Ma'lumot to'ldirish",

  // 404 page
  PAGE_NOT_FOUND_TITTLE: "Ups! Sahifa topilmadi",
  PAGE_NOT_FOUND_FOOTER_TITTLE: "kechirasiz, lekin so'ralgan sahifa topilmadi",
  NAVBAR_LOGIN_TITTLE: "Kirish",

  //Main
  TITTLE_MAIN_PLATFORMA: "Onlayn ta'lim",
  TITTLE_MAIN_PLATFORM: "Platformasi",
  TITTLE_MAIN_TALIM:
    "Ta'lim beruvchilar va ilm oluvchilar uchun onlayn platforma",
  TITTLE_MAIN_KURSLAR: "Kurslar",
  TITTLE_MAIN_VIDEO: "Videoni ko‘rish",
  TITTLE_MAIN_BILIMOLUVCHI: "Bilim oluvchi",
  TITTLE_MAIN_BILIMULASHUVCHI: "Bilim ulashuvchi",
  TITTLE_MAIN_PROFIL: "Profil yarating",
  TITTLE_MAIN_KURSTANGLANG: "Kursni tanlang",
  TITTLE_MAIN_OQISHNI: "O‘qishni boshlang",
  TITTLE_MAIN_TOP: "Top",
  TITTLE_MAIN_DISCOUNT: "Chegirmadagi",
  TITTLE_MAIN_KURS: "kurslar",
  TITTLE_MAIN_XARID: "Xarid qilish",
  TITTLE_MAIN_BOSHLASH: "Boshlash",
  TITTLE_MAIN_DAVOM: "Davom ettirish",
  TITTLE_MAIN_BEPUL: "Bepul",
  TITTLE_MAIN_SOM: "so'm",
  TITTLE_MAIN_BARCHASI: "Barchasi",
  TITTLE_MAIN_YANGI: "Yangi ",
  TITTLE_MAIN_SPIKER: "SPIKERLAR",
  TITTLE_MAIN_DAN: "250 dan",
  TITTLE_MAIN_DANS: "8 dan ",
  TITTLE_MAIN_ORTIQMAXSULOT: "ortiq mutaxassislar",
  TITTLE_MAIN_PROFILS: "profil",
  TITTLE_MAIN_ORTIQSOXA: "ortiq soha va",
  NAVBAR_COURSES_TITTLES: "kurslar",
  TITTLE_MAIN_MARKETING: "Marketing",
  TITTLE_MAIN_DASTURLASH: "Dasturlash",
  TITTLE_MAIN_DIZAYN: "Dizayn",
  TITTLE_MAIN_DIN: "Din va diyonat",
  TITTLE_MAIN_XORIJI: "Xorijiy tillar",
  TITTLE_MAIN_BIZNES: "Biznes",
  TITTLE_MAIN_MENEJMENT: "Menejment",
  TITTLE_MAIN_USTUNLIK: "USTUNLIK",
  TITTLE_MAIN_NEGA: "Nega",
  TITTLE_MAIN_AYNAN: "aynan EduOn",
  TITTLE_MAIN_QULAY: "Qulay vaqt",
  TITTLE_MAIN_PLATFORMADA: "Platforma",
  TITTLE_MAIN_SOAT: "soat rejimida ishlaydi",
  TITTLE_MAIN_ISTALGANJOY: "Istalgan joy",
  TITTLE_MAIN_INTERNETBOLSA: "Internet bo’lsa bas, EduOn har yerda ishlaydi",
  TITTLE_MAIN_MUTAXASISLAR: "Mutaxassis",
  TITTLE_MAIN_DASTURLASHMUTAXASISLAR: "Darslar mutaxassis tomonidan o’tiladi",
  TITTLE_MAIN_AKTUAL: "Aktual",
  TITTLE_MAIN_BARCHAAQTUAL: "Barcha aktual sohalar bo’yicha darslar mavjud",
  TITTLE_MAIN_OSON: "Oson",
  TITTLE_MAIN_SAMARALIILM: "Samarali ilm olish uchun barcha imkoniyatlar",
  TITTLE_MAIN_FIKIRLAR: "FIKRLAR",
  TITTLE_MAIN_ULARBIZXAQIMIZDA: "Ular biz haqimizda",
  TITTLE_MAIN_NIMAFIKIR: "nima fikrda",
  TITTLE_MAIN_ONLINETALIM: "Onlayn Ta‘lim Oling",
  TITTLE_MAIN_PLATFORMAORQALI:
    "Platformamiz orqali istalgan yerda va istalgan vaqtda doimiy ravishda bilim oling",
  TITTLE_MAIN_ROYXATANOTISH: "Ro'yxatdan o'tish",
  TITTLE_MAIN_AKKAUNTINGIZ: "Akkauntingiz bormi",
  TITTLE_MAIN_DIYORSH: "Hasan M.",
  TITTLE_MAIN_OQITUVCHI: "O'qituvchi",
  TITTLE_MAIN_PLATFORMAJUDAQULAY:
    "Kim ta'lim sohasiga o'z hissasini qushmoqchi bulsa Eduon.uz platformasi bunga tayyor.",
  TITTLE_MAIN_PLATFORMAJUDAQULAY2:
    "Siz uchun tekin o'quv markaz ochish  uchun tayyor imkoniyat.",
  TITTLE_MAIN_PLATFORMAJUDAQULAY3:
    "O'zbekistonda bunday platforma ochilganidan juda xursandman .",
  TITTLE_MAIN_SHAVKATHASAN: "Eshonqulov.M",
  TITTLE_MAIN_HUZAYFAB: "Huzayfa B.",
  TITTLE_MAIN_XUSAN: "Husan M.",
  //Main2
  TITTLE_MAIN2_ONLINE: "Onlayn ta'lim",
  TITTLE_MAIN2_PLATFORM: "platformasi",
  TITTLE_MAIN2_KELAJAK:
    "Kelajak yoshlariga sifatli ta'lim berishda o'z hissangizni qo'shing",
  TITTLE_MAIN2_BILIM: "Bilim ulashish",
  TITTLE_MAIN2_VIDEO: "Videoni ko'rish",
  TITTLE_MAIN2_BILIMOLUVCHI: "Bilim oluvchi",
  TITTLE_MAIN2_OQUVCHI: "O'quvchi",
  TITTLE_MAIN2_BILIMULASHUVCHI: "Bilim ulashuvchi",
  TITTLE_MAIN2_OQITUVCHI: "O'qituvchi",
  TITTLE_MAIN2_PROFIL: "Profil yarating",
  TITTLE_MAIN2_SOHANGIZ: "Sohangizni tanlang",
  TITTLE_MAIN2_OQITISH: "O'qitishni boshlang",
  TITTLE_MAIN2_TARIFLAR: "Tashriflar",
  TITTLE_MAIN2_OQUVCHILAR: "O'quvchi",
  TITTLE_MAIN2_SOHA: "Soha",
  TITTLE_MAIN2_SOTILGAN: "ta sotilgan kurslar",
  TITTLE_MAIN2_KURSLAR: "kurslar",
  TITTLE_MAIN2_QADAMLAR: "QADAMLAR",
  TITTLE_MAIN2_TALIMBERISH: "Ta'lim berishni",
  TITTLE_MAIN2_QANDAY: "qanday",
  TITTLE_MAIN2_BOSHLASH: "boshlash mumkin",
  TITTLE_MAIN2_ROYXATDAN: "Ro'yxatdan o'tish",
  TITTLE_MAIN2_BILIMULASHUVCHISIFATIDA:
    "Bilim ulashuvchi sifatida ro'yxatdan o'tish uchun",
  TITTLE_MAIN2_BUYERGA: "bu yerga",
  TITTLE_MAIN2_BOSING: "bosing",
  TITTLE_MAIN2_MALUMOTLAR: "Ma'lumotlarni to'ldirish",
  TITTLE_MAIN2_PLATFORMATOMONIDAN:
    "Platforma tomonidan so'ralgan barcha ma'lumotlar to'liq to'ldirilishi zarur",
  TITTLE_MAIN2_KURSYUKLASH: "Kurs yuklash",
  TITTLE_MAIN2_SOHANGIZBOYICHA: "Sohangiz bo’yicha kurs yuklashni boshlash",
  TITTLE_MAIN2_USTUNLIK: "USTUNLIK",
  TITTLE_MAIN2_NEGA: "Nega",
  TITTLE_MAIN2_AYNAN: "aynan EduOn",
  TITTLE_MAIN2_AUDITORIYA: "Auditoriya",
  TITTLE_MAIN2_OZINGIZSHAXSI: "O'zingizning shaxsiy auditoriyangizni to'plang",
  TITTLE_MAIN2_OQUVCHIMARKAZ: "O'quv markaz",
  TITTLE_MAIN2_BEPUL: "Bepul onlayn o'quv markaz oching",
  TITTLE_MAIN2_STATISTIKA: "Statistika",
  TITTLE_MAIN2_BARCHA: "Barcha statistikalarga ega bo'ling",
  TITTLE_MAIN2_STATISTIK: "Statistika",
  TITTLE_MAIN2_QOSHIMCHA: "Qo'shimcha daromad manbaini kashf eting",
  TITTLE_MAIN2_OSON: "Oson",
  TITTLE_MAIN2_BILIMBERISH:
    "Bilim berish uchun barcha qulayliklarga ega ko'rinish",
  TITTLE_MAIN2_FIKRLAR: "FIKRLAR",
  TITTLE_MAIN2_USTOZLAR: "Ustozlar biz haqimizda ",
  TITTLE_MAIN2_NIMAFIKIRDA: "nima fikrdalar",
  TITTLE_MAIN2_ONLINETALIM: "Onlayn Ta'lim Bering",
  TITTLE_MAIN2_PLATFORMAMIZDA:
    "Platformamiz orqali istalgan yerda va istalgan vaqtda doimiy ravishda bilim bering",
  TITTLE_MAIN2_ROYXAT: "Ro'yxatdan o'tish",
  TITTLE_MAIN2_AKKAUNTINGIZ: "Akkauntingiz bormi",

  //COURSES
  TITTLE_COURSE_BIZNESK: "Biznes",
  TITTLE_COURSE_ITK: "IT va Dasturlash",
  TITTLE_COURSE_AKADEMIKK: "Akademik",
  TITTLE_COURSE_DINK: "Din va Ma'rifat",
  TITTLE_COURSE_BOSHQALARK: "Boshqalar",
  TITTLE_COURSE_YANGIQ: "Yangi qo'shilganlar",
  TITTLE_COURSE_ESKIQ: "Eski qo'shilganlar",
  TITTLE_COURSE_REYTINGQ: "Reyting bo'yicha",
  TITTLE_COURSE_PULQ: "Narx bo'yicha",
  TITTLE_COURSE_BEPULQ: "Bepul kurslar",
  TITTLE_COURSE_TOIFA: "Toifani tanlang",
  TITTLE_COURSE_NARX: "Narx",
  TITTLE_COURSE_FILTER: "Filter",
  TITTLE_COURSE_XUSHKELIBSIZ: "Xush kelibsiz",
  TITTLE_COURSE_ILM: "ilm izlanuvchi",
  TITTLE_COURSE_XOSHBUGUN: "Xo'sh bugun qanday bilimlar o'rganamiz",
  TITTLE_COURSE_BOLIM: "Bo‘limlar ",
  TITTLE_COURSE_BOYICHA: "bo'yicha",
  TITTLE_COURSE_USTOZLAR: "Ustozlar ",
  TITTLE_COURSE_BOYICHAS: "bo'yicha",
  TITTLE_COURSE_BOLIMLAR: "Bo‘limlar ",
  TITTLE_COURSE_YANGIQOSHISH: "Yangi qo'shilganlar",
  TITTLE_COURSE_REYTING: "Reyting",
  TITTLE_COURSE_UZBEKCHA: "O'zbekcha",
  TITTLE_COURSE_RUSCHA: "Ruscha",
  TITTLE_COURSE_INGLISCHA: "Inglizcha",
  TITTLE_COURSE_QOZOQCHA: "Qozoqcha",
  TITTLE_COURSE_TOJIKCHA: "Tojikcha",
  TITTLE_COURSE_BOLIML: "Bo'limlar",
  TITTLE_COURSE_FRONTEND: "Frontend",
  TITTLE_COURSE_BACKEND: "Backend",
  TITTLE_COURSE_MOBILE: "Mobile",
  TITTLE_COURSE_FLUTTER: "Flutter",
  TITTLE_COURSE_SUNIYINTELEKT: "Sun'iy intellekt",
  TITTLE_COURSE_KORSATISH: "Ko‘rsatish",
  TITTLE_COURSE_YANGIQURSLAR: "Yangi Kurslar",
  TITTLE_COURSE_BARCHASI: "Barchasi",
  TITTLE_COURSE_MARKETING: "Marketing",
  TITTLE_COURSE_DASTURLASH: "Dasturlash",
  TITTLE_COURSE_BIZNES: "Biznes",
  TITTLE_COURSE_DINIY: "Diniy",
  TITTLE_COURSE_XORIJIYTIL: "Xorijiy til",
  TITTLE_COURSE_MENEJMENT: "Menejment",
  TITTLE_COURSE_XARIDQILISH: "Xarid qilish",
  TITTLE_COURSE_PROFIL: "Profil",
  TITTLE_COURSE_YANAKORISH: "Yana ko'rish",
  TITTLE_COURSE_SOM: "so‘m",
  TITTLE_COURSE_YANGI: "Yangi qo'shilganlar",
  TITTLE_COURSE_AVALGI: "Avvalgi kurslar",
  TITTLE_COURSE_REYTINGBOYICHA: "Reyting bo'yicha",
  TITTLE_COURSE_NARXBOYICHA: "Narx bo'yicha",
  TITTLE_COURSE_BEPULKURS: "Bepul kurslar",
  TITTLE_COURSE_YANGIQOSHILGANLAR: "Yangi qo'shilganlar",
  TITTLE_COURSE_AVVALGIKURSLAR: "Avvalgi kurslar",
  TITTLE_COURSE_REYTINGBOYICHAS: "Reyting bo'yicha",
  TITTLE_COURSE_NARXBOYICHAS: "Narx bo'yicha",
  TITTLE_COURSE_BEPUL: "Bepul kurslar",

  //FAQ
  TITTLE_FAQ_KOPBERILADIGON: "Kop beriladigan",
  TITTLE_FAQ_SAVOLARGA: "savollarga javoblar",
  TITTLE_FAQ_SAVOLAR: "Savollarga",
  TITTLE_FAQ_JAVOBLAR: "javoblar",
  TITTLE_FAQ_SIZHAMSAVOLYOLLASHINGIZ:
    "Siz ham savol yo’llashingiz mumkin, 6 soat ichida javob beramiz",
  TITTLE_FAQ_BILIMOLUVCHI: "Bilim oluvchi",
  TITTLE_FAQ_OQUVCHI: "O'quvchi",
  TITTLE_FAQ_BILIMULASHUVCHI: "Bilim ulashuvchi",
  TITTLE_FAQ_OQTUVCHI: "O'qituvchi",
  TITTLE_FAQ_BILIM: "Bilim oluvchi",
  TITTLE_FAQ_OQUVCH: "O'quvchi",
  TITTLE_FAQ_BARCHASI: "Barchasi",
  TITTLE_FAQ_TOLOVLAR: "To'lovlar",
  TITTLE_FAQ_KURSLAR: "Kurslar",
  TITTLE_FAQ_XARIDQILISH: "Xarid qilish",
  TITTLE_FAQ_PROFIL: "Profil",
  TITTLE_FAQ_SHIKOYAT: "Shikoyat",
  TITTLE_FAQ_BOSHQALAR: "Boshqalar",
  TITTLE_FAQ_YANAKORSATISH: "Yana ko'rsatish",

  // ID COURSE
  TITTLE_COURSEID_KURSTARKIBI: "Kurs tarkibi",
  TITTLE_COURSEID_OXSHASH: "Spiker va o'xshash kurslar",
  TITTLE_COURSEID_KURSICHIDA: "Kurs ichida",
  TITTLE_COURSEID_UMIRBOD: "Umirbod egalik qilish imkoniyati",
  TITTLE_COURSEID_VIDEO: "ta video",
  TITTLE_COURSEID_IZOHLAR: "Izohlar",

  // PAYMENT
  TITTLE_PAYMENT_HISOBIM: "Hisobim",
  TITTLE_PAYMENT_VAUCHER: "Vaucher",
  TITTLE_PAYMENT_BALANS: "Balans",
  TITTLE_PAYMENT_KARTA: "Karta",
  TITTLE_PAYMENT_SUMMA: "Summa",
  TITTLE_PAYMENT_YETARLIEMAS:
    "Hamyoningizda yetarli mablag' yo'q, hamyoningizni toldiring",
  TITTLE_PAYMENT_KARTANISAQLAB: "Kartani saqlab qo'yish",
  TITTLE_PAYMENT_KARTAMORQALI: "Mening kartam orqali to'lov",
  TITTLE_PAYMENT_TIZIMISHLAMAYAPDI:
    "Hozirda ushbu to'lov tizimi orqali to'lov qilish imkoniyati mavjud emas. Birozdan so'ng qayta urinib ko'rishingizni iltimos qilamiz. Yoki kartangizdagi mablag'ni tekwiring",
  TITTLE_PAYMENT_PUL: "Pul",
  TITTLE_PAYMENT_BEKORQILISH: "Bekor qilish",
  TITTLE_PAYMENT_TASDIQLASHKODI: "Tasdiqlash kodini kiriting",
  TITTLE_PAYMENT_TASDIQLASH: "Tasdiqlash",
  TITTLE_PAYMENT_TABRIKLAYMIZ: "Tabriklaymiz !!!",
  TITTLE_PAYMENT_KURSSOTIBOLINDI: "Kurs sotib olindi.",

  //BLOCK
  TITTLE_BLOCK_ONLINE: "Onlayn",
  TITTLE_BLOCK_TALIM: "ta'lim platformasi",
  TITTLE_BLOCK_OZBEKISTONDAGI: "O’zbekistondagi ilk",
  TITTLE_BLOCK_ONLAYNOQUV: "onlayn ta'lim platformasi",
  TITTLE_BLOCK_TRENINGLARSEMINARLAR:
    "Treninglar, seminarlar, vebinar va onlayn darsliklar uchun yagona o`zbek tilidagi platforma. Muvaffaqiyatli insonlar ilmi bilan bo`lishamiz",
  TITTLE_BLOCK_VIDEONIKORISH: "Videoni ko'rish",
  TITTLE_BLOCK_EDUON: "EduOn",
  TITTLE_BLOCK_HISSA: "hissa",
  TITTLE_BLOCK_QOSHGANLAR: "qo’shganlar",
  TITTLE_BLOCK_BIZHAQIMIZDA: "Biz haqimizda",
  TITTLE_BLOCK_EDUONMFAQTORTOMONIDAN:
    "EduOn MFaktor tomonidan har kimga, hamma joyda hayotni o'zgartiradigan ta'lim tajribalarini taqdim etish niyatida asos solingan",
  TITTLE_BLOCK_HOZIRDABU:
    "Hozirda bu O'zbekiston bo'ylab million o'quvchi kelajak ko'nikmalarini o'rganish uchun yetakchi onlayn ta'lim platformasi",
  TITTLE_BLOCK_OZBEKISTONNINGDAORTIQ:
    "O'zbekistonning dan ortiq eng yaxshi universitetlari va soha o'qituvchilari EduOn bilan hamkorlik qilib, kurslar, mutaxassisliklar, sertifikatlar va diplom dasturlarini taklif qilmoqdalar",
  TITTLE_BLOCK_KURSHAQIDA: "Kurs haqida",
  TITTLE_BLOCK_EDUONDAGIHARBIR:
    "EduOn dagi har bir kursni jahon miqyosidagi universitetlar va kompaniyalarning ustozlari o'qitadilar, shuning uchun siz istagan vaqtingizda va istagan joyda yangi narsalarni o'rganishingiz mumkin",
  TITTLE_BLOCK_YUZLABKURSLARTALABA:
    "Yuzlab kurslar talab bo'yicha video ma'ruzalar, uy vazifalari mashqlari va jamoatchilik muhokamalari forumlariga kirish imkoniyatini beradi. Har kuni o'zingiz uchun yangilik kashf eting",
  TITTLE_BLOCK_VIDEOKORISH: "Videoni ko'rish",
  TITTLE_BLOCK_SHOTARUSTAVELI: "Shota Rustaveli 6-uy",
  TITTLE_BLOCK_ISMINGIZ: "Ismingiz",
  TITTLE_BLOCK_ELEKTRONPOCHTANGOZ: "Elektron pochtangiz",
  TITTLE_BLOCK_TELEFONRAQAMINGIZ: "Telefon raqamingiz",
  TITTLE_BLOCK_XABARINGIZ: "Xabaringiz",
  TITTLE_BLOCK_YUBORISH: "Yuborish",
  TITTLE_BLOCK_HAMKORLARIMIZ: "HAMKORLARIMIZ",

  //FOOTERS
  TITTLE_FOOTERS_OQUVCHI: "O'quvchi",
  TITTLE_FOOTERS_SPIKER: "Spiker",
  TITTLE_FOOTERS_VEBSAYT: "Veb sayt",
  TITTLE_FOOTERS_BOSHSAHIFA: "Bosh sahifa",
  TITTLE_FOOTERS_KURSLAR: "Modullar",
  TITTLE_FOOTERS_FAQ: "FAQ",
  TITTLE_FOOTERS_MALUMOTLAR: "Ma’lumotlar",
  TITTLE_FOOTERS_BIZHAQIMIZDA: "Biz haqimizda",
  TITTLE_FOOTERS_FOYDALANISH: "Foydalanish shartlari",
  TITTLE_FOOTERS_MAXFIYLIK: "Xavfsizlik siyosati",
  TITTLE_FOOTERS_MANZILLAR: "Manzil",
  TITTLE_FOOTERS_TOSHKENTSH: "Namangan shahar, K.Otamirzayev ko'chasi,",
  TITTLE_FOOTERS_RUSTAVELI: "Rustaveli 1-tor ko'chasi, 6-uy",
  TITTLE_FOOTERS_BARCHA: "Barcha huquqlar himoyalangan",

  //Login Pages
  TITTLE_CODE_VERIFY_ERROR: "Tasdiqlash kodi notog'ri!",
  TITTLE_LOGIN_ERROR: "Bunday foydalanuvchi mavjud emas",

  // Validations
  FieldErrorRequired: "Maydon to'ldirilishi shart!!!",
  FieldPasswordErrorShort: "Parolni uzunligi 5ta belgidan uzun bulishi shart",

  //SEARCH PAGE
  TITTLE_SEARCH_QIDIRUVNATIJASI: "Qidiruv natijasi",
  TITTLE_SEARCH_TOPILMADI: "Kurslar topilmadi",
  TITTLE_SEARCH_SPIKERTOPILMADI: "Spiker topilmadi",

  //LOGIN
  LOGIN_FROM_PHONRE: "Telefon orqali",
  LOGIN_PHONRE: "Telefon",
  LOGIN_FROM_MAIL: "Email orqali",
  LOGIN_MAIL: "Email",
  LOGON_INPUT_EMAIL_PLACEHOLDER: "Emailni kiriting",
  LOGIN_TITTLE_FORGET_PSW: "Parolingizni unutdingizmi?",
  LOGIN_TITTLE_REGISTER: "Ro'yhatdan o'tish",
  LOGIN_TITTLE_LOGIN_LOADING: "Iltimos kuting...",
  LOGIN_TITTLE_LOGIN: "Tizimga kirish",
};
