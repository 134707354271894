import qs from "qs";
import Cookies from "universal-cookie";
//@ts-ignore
import { pathParams } from "path-params";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AppLanguage } from "../i18n/I18nContext";
import { API_HOST } from "../constants/AppConstants";
import { AppError } from "../helpers/AppError";

const cookies = new Cookies();
export interface ApiProps {
  readonly token?: string;
  readonly refresh?: string;
  readonly language?: AppLanguage;
  readonly logout?: () => void;
}

export interface Options extends AxiosRequestConfig {
  readonly query?: object | [];
  readonly params?: object | [];
  readonly FormData?: any;
}

export class BaseApi {
  private readonly token?: string;
  private readonly logout?: () => void;
  private readonly refresh?: string;

  constructor({ token, refresh, logout }: ApiProps) {
    this.token = token;
    this.refresh = refresh;
    this.logout = logout;
  }

  private queryToString(query: object = {}): string {
    return qs.stringify(query);
  }

  private createRequestUrl(
    url: string,
    query: object = {},
    params: object = {}
  ): string {
    const formattedUrl = pathParams(url, params);

    return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
  }

  private createRequestOptions(
    options: AxiosRequestConfig
  ): AxiosRequestConfig {
    let { headers = {} } = options;

    if (cookies.get("token")) {
      headers = { Authorization: `Bearer ${cookies.get("token")}` };
    }
    return {
      baseURL: API_HOST,
      ...options,
      headers,
    };
  }

  private request<T>(url: string, options: Options = {}): Promise<T> {
    const { query, params, ...kyOptions } = options;

    const formattedOptions = this.createRequestOptions(kyOptions);
    const formattedUrl = this.createRequestUrl(url, query, params);

    return axios.request({
      url: formattedUrl,
      ...formattedOptions,
    });
  }
  private jsonRequest<T>(url: string, options?: Options): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.request<AxiosResponse>(url, options)
        .then((response) => {
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 400
          ) {
            return response.data || [];
          }

          return this.parseError(response.status, response);
          // return response.data;
        })
        .then((data) => {
          if (data.status === 204) {
            return resolve({} as T);
          }

          return resolve(data);
        })
        .catch(async (error) => {
          if (error.response?.status === 401 && this.logout) {
            this.logout();
          } else if (error.response?.status === 400) {
            // console.log(777, error);
            return this.parseError(400, error.response);
          } else if (error.name === "TimeoutError") {
            reject(
              this.parseError(500, {
                title: "Timeout",
                errors: "Время ожидания подключения истекло, попробуйте позже.",
                violations: [],
              })
            );
          }
        });
    });
  }

  private parseError(statusCode: number, response: any): AppError {
    const error = new AppError(response.data);
    error.status = statusCode;
    error.data = response.data;

    return error?.data;
  }

  public get<T = any>(url: string, options?: Options): Promise<T> {
    return this.jsonRequest(url, { ...options, method: "get" });
  }

  public post<T = any>(url: string, options?: Options): Promise<T> {
    return this.jsonRequest(url, { ...options, method: "post" });
  }

  public put<T = any>(url: string, options?: Options): Promise<T> {
    return this.jsonRequest(url, { ...options, method: "put" });
  }
  public patch<T = any>(url: string, options?: Options): Promise<T> {
    return this.jsonRequest(url, { ...options, method: "patch" });
  }

  public delete<T = any>(url: string, options?: Options): Promise<T> {
    return this.jsonRequest(url, { ...options, method: "delete" });
  }
}
