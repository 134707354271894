import { I18nSchema } from "./I18nSchema";
import { I18nEnglish } from "./I18nEnglish";

export const I18nRussian: I18nSchema = {
  ...I18nEnglish,

  // Navbar
  NAVBAR_MAIN_PAGE_TITTLE: "Главная",
  NAVBAR_COURSES_TITTLE: "Курсы",
  NAVBAR_FAQ_TITTLE: "Помощь",
  NAVBAR_ABOUT_TITTLE: "О нас",
  NAVBAR_EDUBRANDING_TITTLE: "Эду-брендинг",
  NAVBAR_KABINET_TITLE: "Мой кабинет",
  NAVBAR_BILIMBERISH_TITLE: "Преподовать",
  NAVBAR_CHIQISH_TITLE: "Выйти",
  NAVBAR_QIDIRUV_TITLE: "Поиск",

  // ALERT
  ALERT_ASSALOMUALAYKUM: "Здравствуйте",
  ALERT_ROYXATDANOTING: "Сейчас же пройдите регистрацию",
  ALERT_VAUCHERGAEGABOLING: "и получите 50,000 сум в подарок",
  ALERT_ROYXATDANOTISH: "Зарегистрироваться",
  ALERT_MALUMOTTOLDIRISH: "Дополнить информацию",

  // 404 page
  PAGE_NOT_FOUND_TITTLE: "Упс, страница не найдена",
  PAGE_NOT_FOUND_FOOTER_TITTLE:
    "извините, но запрашиваемая страница не найдена",
  NAVBAR_LOGIN_TITTLE: "Логин",

  //Main
  TITTLE_MAIN_PLATFORMA: "Онлайн Платформа",
  TITTLE_MAIN_PLATFORM: "Образование",
  TITTLE_MAIN_TALIM: "Онлайн-платформа для преподавателей и учащихся",
  TITTLE_MAIN_KURSLAR: "Модули",
  TITTLE_MAIN_VIDEO: "Смотреть видео",
  TITTLE_MAIN_BILIMOLUVCHI: "Студент",
  TITTLE_MAIN_BILIMULASHUVCHI: "Ментор",
  TITTLE_MAIN_PROFIL: "Создайте профиль",
  TITTLE_MAIN_KURSTANGLANG: "Выберите курс",
  TITTLE_MAIN_OQISHNI: "Начните обучение",
  TITTLE_MAIN_TOP: "Топ ",
  TITTLE_MAIN_DISCOUNT: "со скидкой",
  TITTLE_MAIN_XARID: "Купить",
  TITTLE_MAIN_BOSHLASH: "Начать",
  TITTLE_MAIN_DAVOM: "Продолжить",
  TITTLE_MAIN_BEPUL: "Бесплатно",
  TITTLE_MAIN_SOM: "сум",
  TITTLE_MAIN_BARCHASI: "Все курсы",
  TITTLE_MAIN_YANGI: "Новые ",
  TITTLE_MAIN_SPIKER: "Менторы",
  TITTLE_MAIN_DAN: "Более 70",
  TITTLE_MAIN_ORTIQMAXSULOT: "спецалисты",
  TITTLE_MAIN_PROFILS: "профиль",
  TITTLE_MAIN_DANS: "Более 8",
  TITTLE_MAIN_ORTIQSOXA: "профессий и",
  NAVBAR_COURSES_TITTLES: "курсов",
  TITTLE_MAIN_MARKETING: "Маркетинг",
  TITTLE_MAIN_DASTURLASH: "Программирование",
  TITTLE_MAIN_XORIJI: "Иностранные языки",
  TITTLE_MAIN_BIZNES: "Бизнес",
  TITTLE_MAIN_MENEJMENT: "Управление",
  TITTLE_MAIN_USTUNLIK: "ПРЕИМУЩЕСТВО",
  TITTLE_MAIN_NEGA: "Почему",
  TITTLE_MAIN_AYNAN: "именно EduOn",
  TITTLE_MAIN_QULAY: "Удобное время",
  TITTLE_MAIN_PLATFORMADA: "Платформа",
  TITTLE_MAIN_SOAT: "работает в режиме часов",
  TITTLE_MAIN_ISTALGANJOY: "Любое место",
  TITTLE_MAIN_INTERNETBOLSA: "Достаточно интернета EduOn работает везде",
  TITTLE_MAIN_MUTAXASISLAR: "Специалисты",
  TITTLE_MAIN_DASTURLASHMUTAXASISLAR: "Курсы ведутся специалистами",
  TITTLE_MAIN_AKTUAL: "Действительный",
  TITTLE_MAIN_BARCHAAQTUAL: "Имеются курсы всех актуальных профессий",
  TITTLE_MAIN_OSON: "Легко",
  TITTLE_MAIN_SAMARALIILM: "Все возможности для эффективного получения знаний",
  TITTLE_MAIN_FIKIRLAR: "МНЕНИИ",
  TITTLE_MAIN_ULARBIZXAQIMIZDA: "",
  TITTLE_MAIN_NIMAFIKIR: "Что думают они про нас",
  TITTLE_MAIN_ONLINETALIM: "Получите знания онлайн",
  TITTLE_MAIN_PLATFORMAORQALI:
    "Получайте знания с нащей платформы в любом месте и в любое время",
  TITTLE_MAIN_ROYXATANOTISH: "Зарегистрироваться",
  TITTLE_MAIN_AKKAUNTINGIZ: "Есть аккаунт?",
  TITTLE_MAIN_DIYORSH: "Xasan.M",
  TITTLE_MAIN_OQITUVCHI: "Учитель",
  TITTLE_MAIN_PLATFORMAJUDAQULAY:
    "Платформа очень удобная. Повышение эффективности обучения имеет несколько функций. Цены тоже не дорогие.",
  TITTLE_MAIN_PLATFORMAJUDAQULAY2:
    "Платформа Eduon готова для всех, кто хочет внести свой вклад в образование. Это тоже платформа вроде Coursera, Udemy.",
  TITTLE_MAIN_PLATFORMAJUDAQULAY3:
    "Интернет и пребывание в сети открывают массу возможностей для развития людей.",
  TITTLE_MAIN_SHAVKATHASAN: "Eshonqulov.M",
  TITTLE_MAIN_HUZAYFAB: "Хузайфа Б.",
  TITTLE_MAIN_XUSAN: "Xusan M.",
  //Main2
  TITTLE_MAIN2_ONLINE: "Онлайн обучение",
  TITTLE_MAIN2_PLATFORM: "Платформа",
  TITTLE_MAIN2_KELAJAK:
    "Содействуйте обеспечению качественого образования будущей молодежи",
  TITTLE_MAIN2_BILIM: "Обмен знаниями",
  TITTLE_MAIN2_VIDEO: "Посмотреть видео",
  TITTLE_MAIN2_BILIMOLUVCHI: "Студент",
  TITTLE_MAIN2_OQUVCHI: "Ментор",
  TITTLE_MAIN2_BILIMULASHUVCHI: "Ментор",
  TITTLE_MAIN2_OQITUVCHI: "Ментор",
  TITTLE_MAIN2_PROFIL: "Создайте профиль",
  TITTLE_MAIN2_SOHANGIZ: "Выберите специализацию",
  TITTLE_MAIN2_OQITISH: "Начните преподование",
  TITTLE_MAIN2_TARIFLAR: "Посещений",
  TITTLE_MAIN2_OQUVCHILAR: "Студенты",
  TITTLE_MAIN2_SOHA: "Профессий",
  TITTLE_MAIN2_SOTILGAN: "Продано курсов",
  TITTLE_MAIN2_KURSLAR: "Курсы",
  TITTLE_MAIN2_QADAMLAR: "ШАГИ",
  TITTLE_MAIN2_TALIMBERISH: "Как",
  TITTLE_MAIN2_QANDAY: "я",
  TITTLE_MAIN2_BOSHLASH: "могу начать преподовать",
  TITTLE_MAIN2_ROYXATDAN: "Зарегистрироваться",
  TITTLE_MAIN2_BILIMULASHUVCHISIFATIDA: "Нажмите",
  TITTLE_MAIN2_BUYERGA: "сюда",
  TITTLE_MAIN2_BOSING: "чтобы зарегистрироваться как ментор",
  TITTLE_MAIN2_MALUMOTLAR: "Заполните информацию",
  TITTLE_MAIN2_PLATFORMATOMONIDAN:
    "Заполните все необходимые свои данные в платформе",
  TITTLE_MAIN2_KURSYUKLASH: "Загрузить курс",
  TITTLE_MAIN2_SOHANGIZBOYICHA: "Начните загружать курсы о вашей специальности",
  TITTLE_MAIN2_USTUNLIK: "ПРЕИМУЩЕСТВО",
  TITTLE_MAIN2_NEGA: "Почему",
  TITTLE_MAIN2_AYNAN: "именно EduOn",
  TITTLE_MAIN2_AUDITORIYA: "Аудитория",
  TITTLE_MAIN2_OZINGIZSHAXSI: "Соберите свою личную аудиторию",
  TITTLE_MAIN2_OQUVCHIMARKAZ: "Учебный центр",
  TITTLE_MAIN2_BEPUL: "Откройте бесплатный онлайн центр обучения",
  TITTLE_MAIN2_STATISTIKA: "Статистика",
  TITTLE_MAIN2_BARCHA: "Получите всю статистику",
  TITTLE_MAIN2_STATISTIK: "Статистика",
  TITTLE_MAIN2_QOSHIMCHA: "Откройте для себя дополнительный источник дохода",
  TITTLE_MAIN2_OSON: "Легкий",
  TITTLE_MAIN2_BILIMBERISH: "Интерфейс со всеми удобствами для обучения",
  TITTLE_MAIN2_FIKRLAR: "МНЕНИИ",
  TITTLE_MAIN2_USTOZLAR: "про нас?",
  TITTLE_MAIN2_NIMAFIKIRDA: "Что думают они",
  TITTLE_MAIN2_ONLINETALIM: "Читать онлайн образование",
  TITTLE_MAIN2_PLATFORMAMIZDA:
    "Постоянно обучайте где угодно и когда угодно через нашу платформу",
  TITTLE_MAIN2_ROYXAT: "Зарегистрироваться",
  TITTLE_MAIN2_AKKAUNTINGIZ: "У тебя есть аккаунт",

  //COURSES
  TITTLE_COURSE_BIZNESK: "Бизнес",
  TITTLE_COURSE_ITK: "ИТ и программирование",
  TITTLE_COURSE_AKADEMIKK: "Академические",
  TITTLE_COURSE_DINK: "Религия",
  TITTLE_COURSE_BOSHQALARK: "Другие",
  TITTLE_COURSE_YANGIQ: "Новые",
  TITTLE_COURSE_ESKIQ: "Старые",
  TITTLE_COURSE_REYTINGQ: "По рейтингу",
  TITTLE_COURSE_PULQ: "По ценам",
  TITTLE_COURSE_BEPULQ: "Бесплатные",
  TITTLE_COURSE_TOIFA: "Категория",
  TITTLE_COURSE_NARX: "Цена",
  TITTLE_COURSE_FILTER: "Фильтр",
  TITTLE_COURSE_XUSHKELIBSIZ: "Добро пожаловать",
  TITTLE_COURSE_ILM: "искатель знаний",
  TITTLE_COURSE_XOSHBUGUN: "Итак, что мы узнаем сегодня",
  TITTLE_COURSE_BOLIM: "По разделам",
  TITTLE_COURSE_BOYICHA: "",
  TITTLE_COURSE_USTOZLAR: "По менторам",
  TITTLE_COURSE_BOYICHAS: "",
  TITTLE_COURSE_BOLIMLAR: "По разделам",
  TITTLE_COURSE_YANGIQOSHISH: "Новые дополнения",
  TITTLE_COURSE_REYTING: "Рейтинг",
  TITTLE_COURSE_UZBEKCHA: "Узбекский",
  TITTLE_COURSE_RUSCHA: "Русский",
  TITTLE_COURSE_INGLISCHA: "Английский",
  TITTLE_COURSE_QOZOQCHA: "Казахский",
  TITTLE_COURSE_TOJIKCHA: "Таджикский",
  TITTLE_COURSE_BOLIML: "Разделы",
  TITTLE_COURSE_FRONTEND: "Фронтенд",
  TITTLE_COURSE_BACKEND: "Бекенд",
  TITTLE_COURSE_MOBILE: "Мобильный",
  TITTLE_COURSE_FLUTTER: "Флаттер",
  TITTLE_COURSE_SUNIYINTELEKT: "Искусственный интеллект",
  TITTLE_COURSE_KORSATISH: "Показать",
  TITTLE_COURSE_YANGIQURSLAR: "Новые курсы",
  TITTLE_COURSE_BARCHASI: "Все",
  TITTLE_COURSE_MARKETING: "Маркетинг",
  TITTLE_COURSE_DASTURLASH: "Программирование",
  TITTLE_COURSE_BIZNES: "Бизнес",
  TITTLE_COURSE_DINIY: "Религиозный",
  TITTLE_COURSE_XORIJIYTIL: "Иностранный язык",
  TITTLE_COURSE_MENEJMENT: "Управление",
  TITTLE_COURSE_XARIDQILISH: "Купить",
  TITTLE_COURSE_PROFIL: "Профиль",
  TITTLE_COURSE_YANAKORISH: "Увидимся снова",
  TITTLE_COURSE_SOM: "сумма",
  TITTLE_COURSE_YANGI: "Новые добавленные",
  TITTLE_COURSE_AVALGI: "Предыдущие курсы",
  TITTLE_COURSE_REYTINGBOYICHA: "По рейтингу",
  TITTLE_COURSE_NARXBOYICHA: "По цену",
  TITTLE_COURSE_BEPULKURS: "Бесплатные курсы",
  TITTLE_COURSE_YANGIQOSHILGANLAR: "Новые дополнения",
  TITTLE_COURSE_AVVALGIKURSLAR: "Предыдущие курсы",
  TITTLE_COURSE_REYTINGBOYICHAS: "По рейтингу",
  TITTLE_COURSE_NARXBOYICHAS: "По цене",
  TITTLE_COURSE_BEPUL: "Бесплатные курсы",

  // ID COURSE
  TITTLE_COURSEID_KURSTARKIBI: "Содержание курса",
  TITTLE_COURSEID_OXSHASH: "Ментор и похожие курсы",
  TITTLE_COURSEID_KURSICHIDA: "Внутри курса",
  TITTLE_COURSEID_UMIRBOD: "Пожижненное владение курсом",
  TITTLE_COURSEID_VIDEO: "видео",
  TITTLE_COURSEID_IZOHLAR: "Комментарии",

  // PAYMENT
  TITTLE_PAYMENT_HISOBIM: "Мой баланс",
  TITTLE_PAYMENT_VAUCHER: "Ваучер",
  TITTLE_PAYMENT_BALANS: "Баланс",
  TITTLE_PAYMENT_KARTA: "Карта",
  TITTLE_PAYMENT_SUMMA: "Сумма",
  TITTLE_PAYMENT_YETARLIEMAS:
    "У вас недостаточно денег в кошельке, пополните свой кошелек",
  TITTLE_PAYMENT_KARTANISAQLAB: "Сохранить карту",
  TITTLE_PAYMENT_KARTAMORQALI: "Оплата моей картой",
  TITTLE_PAYMENT_TIZIMISHLAMAYAPDI:
    "В настоящее время оплата через эту платежную систему невозможна. Пожалуйста, повторите попытку позже. Или проверьте сумму на карте",
  TITTLE_PAYMENT_PUL: "Деньги",
  TITTLE_PAYMENT_BEKORQILISH: "Отменить",
  TITTLE_PAYMENT_TASDIQLASHKODI: "Введите код подтверждения",
  TITTLE_PAYMENT_TASDIQLASH: "Подтвердить",
  TITTLE_PAYMENT_TABRIKLAYMIZ: "Поздравляем !!!",
  TITTLE_PAYMENT_KURSSOTIBOLINDI: "Курс куплен",

  //FAQ
  TITTLE_FAQ_KOPBERILADIGON: "Давая много",
  TITTLE_FAQ_SAVOLARGA: "ответы на вопросы",
  TITTLE_FAQ_SAVOLAR: "На вопросы",
  TITTLE_FAQ_JAVOBLAR: "ответы",
  TITTLE_FAQ_SIZHAMSAVOLYOLLASHINGIZ:
    "Вы также можете отправить вопрос, и мы ответим в течении 6 часов.",
  TITTLE_FAQ_BILIMOLUVCHI: "Ученик",
  TITTLE_FAQ_OQUVCHI: "Читатель",
  TITTLE_FAQ_BILIMULASHUVCHI: "Обмен знаниями",
  TITTLE_FAQ_OQTUVCHI: "Учитель",
  TITTLE_FAQ_BILIM: "Ученик",
  TITTLE_FAQ_OQUVCH: "Ученик",
  TITTLE_FAQ_BARCHASI: "Все",
  TITTLE_FAQ_TOLOVLAR: "Платежи",
  TITTLE_FAQ_KURSLAR: "Курсы",
  TITTLE_FAQ_XARIDQILISH: "Купить",
  TITTLE_FAQ_PROFIL: "Профиль",
  TITTLE_FAQ_SHIKOYAT: "Жалоба",
  TITTLE_FAQ_BOSHQALAR: "Другие",
  TITTLE_FAQ_YANAKORSATISH: "Показать больше",

  //BLOCK
  TITTLE_BLOCK_ONLINE: "В сети",
  TITTLE_BLOCK_TALIM: "платформа обучения",
  TITTLE_BLOCK_OZBEKISTONDAGI: "Первый в Узбекистане",
  TITTLE_BLOCK_ONLAYNOQUV: "платформа онлайн-образования",
  TITTLE_BLOCK_TRENINGLARSEMINARLAR:
    "Уникальный для тренингов, семинаров, вебинаров и онлайн-учебников, национальная платформа, делимся знаниями успешных людей.",
  TITTLE_BLOCK_VIDEONIKORISH: "Смотреть видео",
  TITTLE_BLOCK_EDUON: "",
  TITTLE_BLOCK_HISSA: "Те кто вынесли вклад",
  TITTLE_BLOCK_QOSHGANLAR: "в EduOn",
  TITTLE_BLOCK_BIZHAQIMIZDA: "О нас",
  TITTLE_BLOCK_EDUONMFAQTORTOMONIDAN:
    "EduOn был основан MFactor, с цулью предоставить всем и повсюду возможность обучения, которая изменит жизнь",
  TITTLE_BLOCK_HOZIRDABU:
    "В настоящее время это ведущая образовательная онлайн-платформа, позволяющая миллионам студентов по всему Узбекистану получить новые навыки",
  TITTLE_BLOCK_OZBEKISTONNINGDAORTIQ:
    "Более чем лучшие вузы и преподаватели Узбекистана в сотрудничестве с EduOn предлагают курсы, специальности, сертификаты и дипломные программы",
  TITTLE_BLOCK_KURSHAQIDA: "О курсе",
  TITTLE_BLOCK_EDUONDAGIHARBIR:
    "Каждый курс в EduOn преподается инструкторами из университетов и компаний по всему миру, поэтому вы можете узнавать что-то новое в любое время и в любом месте",
  TITTLE_BLOCK_YUZLABKURSLARTALABA:
    "Сотни курсов предоставляют доступ к видеолекциям по запросу, домашним заданиям и общественным дискуссионным форумам. Открывайте для себя что-то новое каждый день",
  TITTLE_BLOCK_VIDEOKORISH: "осмотреть видео",
  TITTLE_BLOCK_SHOTARUSTAVELI: "Шота Руставели 6 дом",
  TITTLE_BLOCK_ISMINGIZ: "Твое имя",
  TITTLE_BLOCK_ELEKTRONPOCHTANGOZ: "Ваш электронной почты",
  TITTLE_BLOCK_TELEFONRAQAMINGIZ: "Ваш номер телефона",
  TITTLE_BLOCK_XABARINGIZ: "Ваше сообщение",
  TITTLE_BLOCK_YUBORISH: "Отправить",
  TITTLE_BLOCK_HAMKORLARIMIZ: "ПАРТНЕРЫ",

  //FOOTERS
  TITTLE_FOOTERS_OQUVCHI: "Студент",
  TITTLE_FOOTERS_SPIKER: "Ментор",
  TITTLE_FOOTERS_VEBSAYT: "Веб-сайт",
  TITTLE_FOOTERS_BOSHSAHIFA: "Домашняя страница",
  TITTLE_FOOTERS_KURSLAR: "Курсы",
  TITTLE_FOOTERS_FAQ: "Часто задаваемые вопросы",
  TITTLE_FOOTERS_MALUMOTLAR: "Информация",
  TITTLE_FOOTERS_BIZHAQIMIZDA: "О нас",
  TITTLE_FOOTERS_FOYDALANISH: "Условия эксплуатации",
  TITTLE_FOOTERS_MAXFIYLIK: "Конфиденциальность и Политика",
  TITTLE_FOOTERS_MANZILLAR: "Адреса",
  TITTLE_FOOTERS_TOSHKENTSH: "Наманган, улица К.Отамирзаева,",
  TITTLE_FOOTERS_RUSTAVELI: "Руставели 1-я узкая улица, 6-й дом",
  TITTLE_FOOTERS_BARCHA: "Все права защищены",

  // Validations
  FieldErrorRequired: "Это поля обтязательно",
  FieldPasswordErrorShort: "Длина пароля должно быть не менее 6 ти символов",

  //SEARCH PAGE
  TITTLE_SEARCH_QIDIRUVNATIJASI: "Результат поиска",
  TITTLE_SEARCH_TOPILMADI: "Курсы не найдены",
  TITTLE_SEARCH_SPIKERTOPILMADI: "Ментор не найден",

  //LOGIN
  LOGIN_FROM_PHONRE: "По телефону",
  LOGIN_PHONRE: "Телефон",
  LOGIN_FROM_MAIL: "По почте",
  LOGIN_MAIL: "Почта",
  LOGON_INPUT_EMAIL_PLACEHOLDER: "Введите почту",
  LOGIN_TITTLE_FORGET_PSW: "Забыли пароль?",
  LOGIN_TITTLE_REGISTER: "Зарегистрироваться",
  LOGIN_TITTLE_LOGIN_LOADING: "Подождите...",
  LOGIN_TITTLE_LOGIN: "Войти",
};
