import { Action } from "redux";
import { DELETE, update } from "immupdate";
import { PersistConfig } from "redux-persist";
import { UserDetailsProps, UserProps } from "../api/auth/authDTO";
import { AppStoreState } from "../store/RootReducer";
import {
  createReducer,
  createRootReducer,
  FulfillAction,
  PerformAction,
} from "../utils/ReducerUtils";

export const userReducerPersistConfig: Partial<
  PersistConfig<UserReducerState>
> = {
  whitelist: ["user", "userDetails"],
};

interface SetUserMeta {
  readonly user: UserProps;
}
interface IStartQuiz {
  // type: string;
  value: boolean;
}
interface SetUserDetailsMeta {
  readonly userDetails: UserDetailsProps;
}

enum ReducerActions {
  SetUser = "User/SetUser",
  SetUserDetails = "UserDetails/SetUserDetails",
  ClearUserDetails = "UserDetails/ClearUserDetails",
  ClearUser = "User/ClearUser",
  StartQuiz = "User/StartQuiz",
}

export interface UserReducerState {
  readonly user?: UserProps;
  readonly userDetails?: UserDetailsProps;
  openFinal?: boolean;
}

function getState(): UserReducerState {
  return {
    openFinal: false,
  };
}

export const userReducer = createRootReducer<UserReducerState>(
  getState(),

  createReducer([ReducerActions.SetUser], (state, { meta }) =>
    update(state, { user: meta.user })
  ),

  createReducer([ReducerActions.ClearUser], (state) =>
    update(state, { user: DELETE })
  ),

  createReducer([ReducerActions.SetUserDetails], (state, { meta }) =>
    update(state, { userDetails: meta.userDetails })
  ),

  createReducer([ReducerActions.ClearUserDetails], (state) =>
    update(state, { userDetails: DELETE })
  ),

  createReducer([ReducerActions.StartQuiz], (state, { value }) => {
    // update(state, { openFinal: meta.user });
    // console.log("action", value, state);
    return { ...state, openFinal: value };
  })
);

// ==================
// Selectors
// ==================

export const userSelector = ({
  user,
}: AppStoreState): UserProps | undefined | any => user.user;

export const quizModalToggleSelector = ({ user }: any): any => {
  // console.log(user.openFinal);
  return user.openFinal;
};

export const userIdSelector = ({ user }: AppStoreState): string | undefined =>
  user.user?.id;

export const userPhoneSelector = ({
  user,
}: AppStoreState): string | undefined => user.user?.phone;

export const userFirstNameSelector = ({
  user,
}: AppStoreState): string | undefined => user.user?.first_name;

export const userLastNameSelector = ({
  user,
}: AppStoreState): string | undefined => user.user?.last_name;

export const userDetailsSelector = ({
  user,
}: AppStoreState): UserDetailsProps | undefined | any => user.userDetails;
// ==================
// Actions
// ==================

export function setUser(meta: SetUserMeta): PerformAction<SetUserMeta> {
  return { type: ReducerActions.SetUser, meta };
}

export function startQuiz(value: boolean) {
  return { type: ReducerActions.StartQuiz, value } as any;
}

export function clearUser(): Action {
  return { type: ReducerActions.ClearUser };
}

export function setUserDetails(
  meta: SetUserDetailsMeta
): PerformAction<SetUserDetailsMeta> {
  return { type: ReducerActions.SetUserDetails, meta };
}

export function clearUserDetails(): Action {
  return { type: ReducerActions.ClearUserDetails };
}
