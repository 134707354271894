import "./navbar.scss";
import { AiOutlineMenu, AiFillFlag, AiOutlineSetting } from "react-icons/ai";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import { BsFullscreen, BsFillPatchQuestionFill } from "react-icons/bs";
import { SlChemistry } from "react-icons/sl";
import { GiNotebook } from "react-icons/gi";
import { FcCalculator } from "react-icons/fc";
import { IoColorPaletteOutline } from "react-icons/io5";
// import { GoTextSize } from "react-icons/go";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { pageTestSelector } from "../../reducers/appReducer";
import { useHistory } from "react-router";
import logo from "../../components/images/logo.svg";
import { Dispatch, SetStateAction, useState } from "react";
import { startQuiz } from "../../reducers/userReducer";
import { useDispatch } from "react-redux";

interface Props {
  readonly nextClick: () => void;
  readonly prevClick: () => void;
  readonly open?: boolean;
  readonly setOpen?: Dispatch<SetStateAction<boolean>>;
}

const Navbar = ({ nextClick, prevClick, setOpen }: Props) => {
  const page = useShallowEqualSelector(pageTestSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <header className="header-test">
      <ul className="nav">
        <li style={{ display: "flex", alignItems: "center" }}>
          <li className="bg-light" style={{ margin: "0 11px" }}>
            <img
              src={logo}
              alt=""
              onClick={() => setOpen && setOpen(true)}
              width={40}
              height={40}
              style={{
                background: "#FFFFFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          </li>
          <li className="menu">
            <AiOutlineMenu size={25} />
          </li>
        </li>
        <li style={{ display: "flex", alignItems: "center", margin: "0 5px" }}>
          <li className="user-id" style={{ margin: "0 11px" }}>
            <span>
              {window.location.pathname.includes("/test/")
                ? page
                : window.localStorage.getItem("qCount") + " "}
              of {window.localStorage.getItem("qCount")}
            </span>
          </li>
          <li className="transfer-btns">
            {window.location.pathname.includes("/test/") ? (
              <>
                <button
                  className="submit-btn"
                  style={{
                    padding: "10px 25px",
                    background: "royalblue",
                    border: "none",
                    color: "#fff",
                    borderRadius: "8px",
                    fontSize: "18px",
                  }}
                  onClick={() => dispatch(startQuiz(true))}
                >
                  Testni yakunlash
                </button>
              </>
            ) : (
              <></>
            )}
          </li>
        </li>
        {/* <li className="nav-mark-btn" style={{ opacity: "0" }}>
          <input type="checkbox" />
          <AiFillFlag />
          <span>Mark</span>
        </li> */}

        {/* <li className="full-screen">
          <BsFullscreen size={20} />
          <span>Full Screen</span>
        </li>
        <li className="question-btn" style={{ opacity: "0" }}>
          <BsFillPatchQuestionFill />
          <span>Tutorial</span>
        </li>
        <li className="chemistry-btn" style={{ opacity: "0" }}>
          <SlChemistry size={20} />
          <span>Lab Values</span>
        </li>
        <li className="notes-btn" style={{ opacity: "0" }}>
          <GiNotebook size={20} />
          <span>Notes</span>
        </li>
        <li className="calculator-btn" style={{ opacity: "0" }}>
          <FcCalculator size={20} />
          <span>Calculator</span>
        </li>
        <li className="revers-color-btn" style={{ opacity: "0" }}>
          <IoColorPaletteOutline size={20} />
          <span>Revers Color</span>
        </li>
        <li className="text-zoom" style={{ opacity: "0" }}>
          <GoTextSize size={20} />
          <span>Text Zoom</span>
        </li>
        <li className="setting-btn" style={{ opacity: "0" }}>
          <AiOutlineSetting size={20} />
          <span>Settings</span>
        </li> */}
      </ul>
    </header>
  );
};

export default Navbar;
