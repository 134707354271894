import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

interface Props {
    readonly classname: string;
}

export default function SkeletonLoader({classname}: Props) {
    return (
        <SkeletonTheme highlightColor="#C6D4E1">
            <div className={`${classname}`}>
                <div className="cours_sec2_imgBlock"><Skeleton height={"100%"}/></div>
                <div style={{marginTop: "5px"}}><Skeleton height={20} width={252}/></div>
                <div style={{marginTop: "5px"}}><Skeleton height={20} width={252}/></div>
                <div style={{marginTop: "5px"}}><Skeleton height={30} width={252}/></div>
            </div>
        </SkeletonTheme>
    )
}