import { isPlainObject } from "lodash";

export interface AppErrorProps<T = any> extends Omit<Error, "name"> {
  data?: T;
  name?: string;
  status: number;
}

export class AppError implements AppErrorProps {
  public name: string;
  public status: number;
  public message: string;

  public readonly isAppError: boolean = true;

  data: any;

  constructor({
    data = [],
    name = "AppError",
    status,
    message,
  }: AppErrorProps) {
    const dataName = data && isPlainObject(data) ? data.name : name;
    const dataMessage = data && isPlainObject(data) ? data.message : "";
    // console.log(message, "deoorr")
    this.data = data;
    this.name = dataName;
    this.status = status;
    this.message = message || dataMessage;
  }
  public getErrors(): string[] {
    // @ts-ignore
    return this.data.map(({ userMsg }) => userMsg).filter(Boolean);
  }
}
