import React from "react";
import { pdfUrlSelector } from "../../../reducers/appReducer";
import { useShallowEqualSelector } from "../../../hooks/useShallowSelector";
import PdfReader from "../UserVideosPage/PdfReader";

const ShowReadingMaterial = () => {
  const pdfUrl = useShallowEqualSelector(pdfUrlSelector);

  return (
    <div>
      <PdfReader fileUrl={pdfUrl} />
    </div>
  );
};

export default ShowReadingMaterial;
