import { BaseApi, Options } from "../BasiApi";
import { Base_Url } from "../../constants/AppConstants";

export class CourseApi extends BaseApi {
  public getCourses(options?: Options): Promise<any> {
    return this.get(`${Base_Url}/get-course/`, options);
  }
  public getFilteredCourses(options?: Options): Promise<any> {
    return this.get(`${Base_Url}/get-course/`, options);
  }
  public getTopCourse(): Promise<any> {
    return this.get(`${Base_Url}/top-course/`);
  }
  public getDiscountCourse(): Promise<any> {
    return this.get(`${Base_Url}/get-courses-with-discount/`);
  }
  public getVerified(): Promise<any> {
    return this.get(`${Base_Url}/verified-courses/`);
  }
  public getSpeakerCourses(options: Options): Promise<any> {
    return this.get(`${Base_Url}/get-speaker-courses/`, options);
  }
  public buyCourse(options: Options): Promise<any> {
    return this.post(`${Base_Url}/buy-course/`, options);
  }
  public getBoughtCourse(): Promise<any> {
    return this.get(`${Base_Url}/get-boughted-course/`);
  }
  public getBoughtCourseById(options: Options): Promise<any> {
    return this.get(`${Base_Url}/get-single-course-detail/`, options);
  }
  public getCourseDetailsById(options: Options): Promise<any> {
    return this.get(`${Base_Url}/course-detail/`, options);
  }
  public getNewCourses(): Promise<any> {
    return this.get(`${Base_Url}/new-course`);
  }
  public searchCourse(options: Options): Promise<any> {
    console.log("bu search course");
    return this.get(`${Base_Url}/get-course-search/`, options);
  }
  public searchCourse2(options: Options): Promise<any> {
    console.log("bu search course2");

    return this.get(`${Base_Url}/list-course/`, options);
  }
  public getUsers(options: Options): Promise<any> {
    return this.get(`${Base_Url}/users/`, options);
  }
  public getBalance(): Promise<any> {
    return this.get(`${Base_Url}/get-cash-balance/`);
  }
  public getFilterByLanguage(options: Options): Promise<any> {
    return this.get(`${Base_Url}/filter-by-language/`, options);
  }
  public getFilterByPrice(options: Options): Promise<any> {
    return this.get(`${Base_Url}/filter-by-cost/`, options);
  }
  public setReyting(options: Options): Promise<any> {
    return this.post(`${Base_Url}/set-rayting/`, options);
  }
  public getReyting(options: Options): Promise<any> {
    return this.get(`${Base_Url}/get-rayting/`, options);
  }
  public getTest(testId: string, page: number): Promise<any> {
    return this.get(`/quizzes/quizzes/my/${testId}/questions/?page=${page}`);
  }
  public submitQuiz(answerId: string, questionId: string) {
    return this.patch(
      `/quizzes/save-answer/?question=${questionId}&answers=${answerId}`
    );
  }
  public finishSubmit(json: { duration: number }, tesId: string) {
    return this.post(`/quizzes/quizzes/${tesId}/submit/`, { data: json });
  }
  public quizTaker(json: { user: number; quiz: number }) {
    return this.post(`/quizzes/quiztaker/create/`, { data: json });
  }

  public createAnswers(json: { quiz: number; quiz_taker_id: number }) {
    return this.post("/quizzes/answers/create/", { data: json });
  }
  public createFileCheck(
    json: {
      id?: number;
      duration: number;
      user: number;
      video: number;
      file?: number;
      courseModule?: number;
    },
    id: string
  ) {
    return this.patch(`${Base_Url}/user/check/file/${id}/`, { data: json });
  }
  public createFileCheckFirst(json: {
    id?: number;
    duration: number;
    user: number;
    video: number;
    file?: number;
    courseModule?: number;
  }) {
    return this.post(`${Base_Url}/user/check/file/`, { data: json });
  }
}
