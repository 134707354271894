import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import "../../components/css/LoginPage.css";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Hide from "../../components/icons/Hide.svg";
import View from "../../components/icons/View.svg";
import { Routes } from "../../constants/Routes";
import { Formik } from "formik";
import { InputField } from "../../components/forms/InputField";
import { useAuthContext } from "../../api/auth/authContext";
import { useDispatch } from "react-redux";
import { setUser } from "../../reducers/userReducer";
import {
  setAuthDate,
  setRefreshToken,
  setToken,
} from "../../reducers/authReducer";
import Cookies from "universal-cookie";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useI18n } from "../../i18n/I18nContext";

const INITIAL_VALUES = {
  phone: "",
  password: "",
};

interface Props {
  readonly switchUser: boolean;
  readonly setSwitchUser: Dispatch<SetStateAction<boolean>>;
}

export default function LoginPage({ setSwitchUser, switchUser }: Props) {
  const location: any = useLocation();
  const [type, setType] = useState(true);
  const [phone, setPhone] = useState<any>("");
  const [error, setError] = useState("");
  const cookies = new Cookies();
  const { translate } = useI18n();
  const [loading, setLoading] = useState(false);
  const { authApi } = useAuthContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const submitHandlerSpeaker = useCallback(
    (value) => {
      setLoading(true);
      if (value.password.length > 0 && phone.length > 0) {
        const form = new FormData();
        form.append("phone", phone);
        form.append("password", value.password);
        authApi
          .login({
            data: {
              email: phone,
              password: value.password.replace(/\s/g, ""),
            },
          })
          .then((res) => {
            if (res.success === true) {
              setLoading(false);
              let user = res?.data?.student;
              let token = res?.data?.token?.access;
              let refreshToken = res?.data?.token?.refresh;
              dispatch(setUser({ user }));
              dispatch(setToken({ token }));
              cookies.set("token", token, { path: "/" });
              dispatch(setRefreshToken({ refreshToken }));
              cookies.set("refreshToken", refreshToken, { path: "/" });
              dispatch(setAuthDate({ authDate: Date.now() }));
              history.push({ pathname: Routes.Courses });
              localStorage.setItem("phoneUser", phone);
            } else {
              setLoading(false);
              setError(res.error);
            }
          })
          .catch((err) => {
            setError(err.message);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setError("Barcha maydolanlar to'ldirilishi shart!!!");
      }
    },
    [history, dispatch, phone, authApi, cookies]
  );
  const submitHandler = useCallback(
    (value) => {
      setLoading(true);
      if (value.password.length > 0 && phone.length > 0) {
        let phoneNumber = phone.replace(/\s/g, "");
        phoneNumber =
          phoneNumber.length === 9
            ? "998" + phoneNumber
            : phoneNumber.includes("+")
            ? phoneNumber.replace("+", "")
            : phoneNumber;
        phoneNumber = phoneNumber.substr(0, 25);
        axios
          .post("https://inpuls-backend.backoffice.uz/api-web/login/", {
            phone: phoneNumber,
            password: value.password.replace(/\s/g, ""),
          })
          .then((res: any) => {
            // console.log(res.data, "data");
            if (res.data.success === true) {
              setLoading(false);
              let refreshToken = res?.data?.data?.token?.refresh;
              dispatch(setUser({ user: res?.data?.data?.student }));
              dispatch(setToken({ token: res?.data?.data?.token?.access }));
              cookies.set("token", res?.data?.data?.token?.access, {
                path: "/",
              });
              dispatch(setRefreshToken({ refreshToken }));
              cookies.set("refreshToken", refreshToken, { path: "/" });
              dispatch(setAuthDate({ authDate: Date.now() }));
              history.push({ pathname: Routes.Courses });
              localStorage.setItem("phoneUser", phoneNumber);

              // if (res?.data?.data?.student.id !== null) {
              //   if (!!location?.state){
              //     history.push({
              //       pathname: location?.state.oldUrl
              //     });
              //     // history.goBack()
              //   }else{f
              //     history.push({pathname: Routes.Courses})
              //   }
              //   // history.goBack();
              //   localStorage.setItem('phoneUser', phoneNumber);
              // }
            } else {
              setLoading(false);
              setError(res.data.error);
            }
          })
          .catch((err) => {
            setError(err.response?.data.error);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setError("Barcha maydolanlar to'ldirilishi shart!!!");
      }
    },
    [phone, authApi, history, dispatch, cookies]
  );
  return (
    <>
      <section className="sectionLoginPage">
        <div className="container">
          <Formik
            onSubmit={(values) => {
              switchUser ? submitHandler(values) : submitHandlerSpeaker(values);
            }}
            initialValues={INITIAL_VALUES}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="mainLoginPage">
                  <div className="LoginPage-inner">
                    <div className="login-courses_sec_btn ">
                      <h2
                        className={
                          switchUser ? "course_btn_active" : "course_btn_pass"
                        }
                      >
                        {translate("LOGIN_FROM_PHONRE")}
                      </h2>
                      <h3
                        className={`${
                          switchUser ? "course_btn_active" : "course_btn_pass"
                        } Bilim-oluvchi`}
                      >
                        {translate("LOGIN_PHONRE")}
                      </h3>
                      <button
                        onClick={() => {
                          setSwitchUser((prev) => !prev);
                        }}
                        className={switchUser ? "course_btn_1" : "course_btn_2"}
                        type="button"
                      >
                        <div></div>
                      </button>
                      <h2
                        className={
                          switchUser ? "course_btn_pass" : "course_btn_active"
                        }
                      >
                        {translate("LOGIN_FROM_MAIL")}
                      </h2>
                      <h3
                        className={`${
                          switchUser ? "course_btn_pass" : "course_btn_active"
                        } Bilim-oluvchi`}
                      >
                        {translate("LOGIN_MAIL")}
                      </h3>
                    </div>
                  </div>

                  <div className="loginPage-input-item">
                    {switchUser ? (
                      <PhoneInput
                        containerClass="signUpPage-inner-item phone-flag"
                        country={"uz"}
                        // value={phone}
                        masks={{ uz: "(..) ...-..-.." }}
                        onChange={(e) => setPhone(e)}
                      />
                    ) : (
                      <input
                        className="loginPage-inner-item email-input"
                        type="email"
                        placeholder={translate("LOGON_INPUT_EMAIL_PLACEHOLDER")}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    )}

                    <InputField
                      inputClassName="loginPage-inner-item"
                      type={type ? "password" : "text"}
                      placeholder="Parol"
                      name="password"
                    />
                    {type ? (
                      <button
                        className="loginPage-inner-icon"
                        onClick={() => setType((prev) => !prev)}
                        type="button"
                      >
                        <img src={Hide} alt="" />
                      </button>
                    ) : (
                      <label>
                        <button
                          className="loginPage-inner-icon"
                          onClick={() => setType((prev) => !prev)}
                          type="button"
                        >
                          <img src={View} alt="" />
                        </button>
                      </label>
                    )}
                    {error && <div className="error-text">{error}</div>}
                    <NavLink to={Routes.ForgotPassword1}>
                      <p className="loginPage-text-item">
                        {translate("LOGIN_TITTLE_FORGET_PSW")}
                      </p>
                    </NavLink>
                    <div className="buttonBox">
                      <button
                        className="loginPage-btn-inner"
                        onClick={() =>
                          history.push({
                            pathname: Routes.SignUp1,
                            state: { oldUrl: location?.state?.oldUrl },
                          })
                        }
                      >
                        {translate("LOGIN_TITTLE_REGISTER")}
                      </button>
                      <button
                        className="loginPage-btn-inner-kirish"
                        // type="submit"
                      >
                        {loading ? (
                          <div className="block-loading">
                            <p className="">
                              {translate("LOGIN_TITTLE_LOGIN_LOADING")}
                            </p>
                          </div>
                        ) : (
                          translate("LOGIN_TITTLE_LOGIN")
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="loginPage-btn-item" />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
