import React from "react";

interface Props {
  readonly img: string;
  readonly title: string;
}

function Stucture1({ title, img }: Props) {
  return (
    <div className="stc2">
      {img ? (
        <div className="img-div-2">
          <img src={img} alt="" className="image-main-full" />
        </div>
      ) : (
        ""
      )}
      <span className="w-100 mt-2" style={{ fontSize: "22px" }}>
        {title}
      </span>
    </div>
  );
}

export default Stucture1;
