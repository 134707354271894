import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import searchIcon from "./icons/Search.svg";
// import close from "./icons/close.svg"
import logo from "./images/logo.svg";
// import menu from "./icons/menu.svg"
import { Link, useHistory } from "react-router-dom";
import "./css/header.css";
import { Routes } from "../constants/Routes";
import { AppLanguage, listLanguages, useI18n } from "../i18n/I18nContext";
import { appLanguageSelector, switchLanguage } from "../reducers/appReducer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { useDispatch } from "react-redux";
import LanguageIcon from "@material-ui/icons/Language";
import { useCourseContext } from "../api/course/CourseContext";
import {
  userFirstNameSelector,
  // userIdSelector,
  userLastNameSelector,
  userSelector,
} from "../reducers/userReducer";
import { tokenSelector } from "../reducers/authReducer";
import { useAuthContext } from "../api/auth/authContext";
import { useCategoryContext } from "../api/category/CategoryContext";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Cookies from "universal-cookie";
import useSearchDebounce from "../hooks/useSearchDebounce";
interface Props {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

export default function Navbar({ setSearchText, searchText }: Props) {
  const dispatch = useDispatch();
  const { translate } = useI18n();
  const cooikes = new Cookies();
  const history = useHistory();
  const { logout } = useAuthContext();
  const [search, setSearch] = useState(false);
  const [changedText, setChangedText] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [toggle, setToggle] = useState(false);
  const [cashBonus, setCashbonus] = useState("");
  const token = useShallowEqualSelector(tokenSelector);
  const language = useShallowEqualSelector(appLanguageSelector);
  const user = useShallowEqualSelector(userSelector);
  const userName = useShallowEqualSelector(userFirstNameSelector);
  const lastName = useShallowEqualSelector(userLastNameSelector);
  const { courseApi } = useCourseContext();
  // const [filteredCategories, setFilteredCategories] = useState<any>(categories);

  const onSelect = (value: AppLanguage) => {
    dispatch(switchLanguage({ language: value }));
    setToggle(false);
  };

  const test = (pathname: string) => {
    history.push("/user-profile/courses");
    setToggle(false);
  };

  const list = useMemo(
    () => listLanguages().filter((x) => x !== language),
    [language]
  );

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const isAuthorized = useMemo(() => token || user, [token, user]);

  useEffect(() => {
    if (isAuthorized) {
      courseApi.getBalance().then((res) => setCashbonus(res.bonus + res.cash));
    }
  }, [isAuthorized, courseApi]);
  // useEffect(() => {
  //   categoryApi.getCategory().then((res: any) => {
  //     setFilteredCategories(res.data);
  //   });
  // }, [categoryApi]);
  // const categoryFilter = useMemo(
  //   () =>
  //     !!filteredCategories &&
  //     filteredCategories.filter((x: any) => x.course_count > 0),
  //   [filteredCategories]
  // );jewjkjrerekweewwe
  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.title + option.label,
  });

  useEffect(() => {
    if (cooikes.get("token")) {
      courseApi.getFilteredCourses().then((res) => {
        // console.log(res);
        res?.data?.forEach((course: any) => {
          setOptions((prev: any) => [
            ...prev,
            {
              id: course.id,
              img: course.image,
              title: course.name,
              label: course.author.full_name,
            },
          ]);
        });
      });
    }
  }, [courseApi]);

  return (
    <>
      <header
        className={
          window.location.pathname.includes("/test/") ||
          window.location.pathname.includes("/result")
            ? "d-none"
            : ""
        }
      >
        <div className="navbar__asos">
          <nav className="container nav-container">
            <div className="logo">
              <Link to="/">
                <img className="align-items-start" src={logo} alt="" />
              </Link>
            </div>
            <div className="gamburger">
              {/* {JSON.stringify(toggle)} */}
              <ul className={toggle ? "toggle" : ""}>
                <div className="menu__login">
                  <div className="menu__login__tillar">
                    <a>{language}</a>
                    {list.map((x) => (
                      <a key={x} onClick={() => onSelect(x)}>
                        {x}
                      </a>
                    ))}
                  </div>
                  <div className="menu__login__btn">
                    {token === undefined && user === undefined ? (
                      <Link
                        onClick={() => setToggle((prevState) => !prevState)}
                        to={Routes.AuthPage}
                      >
                        Kirish
                      </Link>
                    ) : (
                      <li className="header-link">{cashBonus} so'm</li>
                    )}
                  </div>
                </div>

                {token === undefined && user === undefined ? (
                  <></>
                ) : (
                  <li className="header-link" onClick={() => test("pathname")}>
                    {/* <Link to={Routes.UserProfile}> */}
                    {translate("NAVBAR_KABINET_TITLE")}
                    {/* </Link> */}
                  </li>
                )}
                {token === undefined && user === undefined ? (
                  <></>
                ) : (
                  <li className="header-link">
                    <Link onClick={() => logout(() => setToggle(false))} to="">
                      Chiqish
                    </Link>
                  </li>
                )}
                {/* <li className="menu__close">
                                    <img src={close} onClick={() => setToggle(prevState => !prevState)} alt=""
                                         width="25"/>
                                </li> */}
              </ul>
            </div>

            <div className="navbar">
              {token && (
                <div className="search for_desktop">
                  <div className="search__input">
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        changedText
                          ? history.push(`/search/${changedText}`)
                          : history.push(`/search/${searchText}`);
                      }}
                    >
                      <span className="ic">
                        <img src={searchIcon} alt="" width="20" />
                      </span>
                      <Autocomplete
                        // multiple
                        // limitTags={2}
                        id="multiple-limit-tags"
                        options={options}
                        filterOptions={filterOptions}
                        getOptionLabel={(option) => option?.title}
                        onChange={(e: any, event: any) => {
                          e.preventDefault();

                          if (event) {
                            setSearchText(event.title);
                            history.push(`/search/${event.id}`);
                          }

                          // searchHandler();
                        }}
                        renderTags={(options) => {
                          return options.map((option) => (
                            <div>{option.title}</div>
                          ));
                        }}
                        renderOption={(option) => {
                          return (
                            <div className="search_option">
                              <div className="search_option_img">
                                <img src={option.img} alt="icon" />
                                {/*Mock image, attribute in option*/}
                              </div>
                              <div>
                                <h4>{option.title}</h4>
                                <p>{option.label}</p>
                              </div>
                            </div>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e: any) => {
                              setChangedText(e.target.value);
                            }}
                            variant="outlined"
                            placeholder="Kurs izlash"
                          />
                        )}
                      />
                    </form>
                  </div>
                </div>
              )}
              {search && token && (
                <div className="search">
                  <div className="search__input">
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        changedText
                          ? history.push(`/search/${changedText}`)
                          : history.push(`/search/${searchText}`);
                      }}
                    >
                      <span className="ic">
                        <img src={searchIcon} alt="" width="20" />
                      </span>
                      <Autocomplete
                        // multiple
                        // limitTags={2}
                        id="multiple-limit-tags"
                        filterOptions={filterOptions}
                        options={options}
                        getOptionLabel={(option) => option?.title}
                        onChange={(e: any, event: any) => {
                          e.preventDefault();
                          if (event) {
                            // console.log(444444, e, event);
                            setSearchText(event.title);
                            history.push(`/search/${event.title}`);
                          }

                          // searchHandler();
                        }}
                        renderTags={(options) => {
                          return options.map((option) => (
                            <div>{option.title}</div>
                          ));
                        }}
                        renderOption={(option) => {
                          return (
                            <div className="search_option">
                              <div className="search_option_img">
                                <img src={option.img} alt="icon" />
                                {/*Mock image, attribute in option*/}
                              </div>
                              <div>
                                <h4>{option.title}</h4>
                                <p>{option.label}</p>
                              </div>
                            </div>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e: any) => {
                              setChangedText(e.target.value);
                            }}
                            variant="outlined"
                            placeholder="Kurs izlash"
                          />
                        )}
                      />
                    </form>
                    <button
                      onClick={() => setSearch((prevState) => !prevState)}
                    >
                      <span></span>
                    </button>
                  </div>
                </div>
              )}

              <div className="login__bar">
                {!search && token ? (
                  <>
                    {toggle === false && (
                      <button
                        id="search-btn"
                        onClick={() => {
                          setToggle(false);
                          setSearch((prevState) => !prevState);
                        }}
                      >
                        <img
                          src={searchIcon}
                          alt="mobile search input icon"
                          width="20"
                        />
                      </button>
                    )}
                    <button
                      className={`menu425 ${toggle ? "active" : ""}`}
                      onClick={() => setToggle((prev) => !prev)}
                    >
                      <span />
                    </button>
                  </>
                ) : (
                  <button className="close__btn" />
                )}
                <div className="language">
                  <LanguageIcon color="primary" />
                  <p>{language}</p>
                  <ul className="languages">
                    {list.map((x) => (
                      <li key={x} onClick={() => onSelect(x)}>
                        {x}
                      </li>
                    ))}
                  </ul>
                  {/* <img src={arrowdown} alt="" width="25" /> */}
                </div>
                {token === undefined && user === undefined ? (
                  <Link to={Routes.AuthPage} className="kirish">
                    {translate("NAVBAR_LOGIN_TITTLE")}
                  </Link>
                ) : (
                  <div className="login_btn">
                    <p className="kirish" style={{ cursor: "pointer" }}>
                      {user?.first_name && user?.last_name
                        ? user?.first_name?.toLocaleUpperCase().substr(0, 1) +
                          user?.last_name?.toLocaleUpperCase().substr(0, 1)
                        : "User"}
                    </p>
                    <div className="login_popup">
                      <div className="login_popu__body">
                        {/* {token !== undefined && user !== undefined ? (
                          <div className="login_popup__item balance-nav">
                            {cashBonus} {translate("TITTLE_MAIN_SOM")}
                          </div>
                        ) : (
                          <></>
                        )} */}

                        <Link to={Routes.UserProfile}>
                          <div className="login_popup__item">
                            {translate("NAVBAR_KABINET_TITLE")}
                          </div>
                        </Link>
                        {/* <div
                          className="login_popup__item"
                          onClick={() => window.location.assign('https://speaker.eduon.uz/login')}
                        >
                          {translate('NAVBAR_BILIMBERISH_TITLE')}
                        </div> */}
                        <div className="login_popup__item" onClick={logout}>
                          {translate("NAVBAR_CHIQISH_TITLE")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
        <div className="fixed"></div>
      </header>
    </>
  );
}
