import "../css/main.css"
import "../loader/assets/loader.css"
export default function Loader () {
    return (
        <div className="box">
            <div className="loaderc loadbox">
                <div className="loaderc div"></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
