import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import "../../components/css/SignUpPage2.css";
import {useAuthContext} from "../../api/auth/authContext";
import {Routes} from "../../constants/Routes";
import {Formik} from "formik";
import {InputField} from "../../components/forms/InputField";
import {useI18n} from "../../i18n/I18nContext";
import axios from "axios";


const INITIAL_VALUES = {
    code: "",
};

interface Props {
    readonly switchUser: boolean;
}

export default function SignUpPage2({switchUser}: Props) {
    const location:any = useLocation()
    const {authApi} = useAuthContext();
    const {translate} = useI18n();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [error, setError] = useState("");
    const [phone, setPhone] = useState<string | any>("");
    const [seconds, setSeconds] = useState(59);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [counter, setCounter] = useState(1);
    const [minutes, setMinutes] = useState(0)
    const [color, setColor] = useState("#a9a9a9");
    const [retryButton, setRetryButton] = useState("Qayta yuborish uchun")
    const [password, setPassword] = useState("")
    const [isMessageOpen, setMessageOpen] = useState("none")
    useEffect(() => {

    }, [resendDisabled])

    useEffect(() => {

    }, [isMessageOpen])

    const resendHandler = useCallback(() => {
        authApi.loginCredentials({
            query: {
                phone: phone,
                type: "registeration"
            }}).then(res => console.log(""))
    }, [phone, authApi]);
    useEffect(() => {
        let timer = setInterval(() => {
            if(seconds !== 0){
             setSeconds(seconds - 1)
            }else if(seconds === 0 && minutes === 1){
                setMinutes(0)
                setSeconds(59)
            }else{
                setResendDisabled(false)
                setMessageOpen("block")
                if(counter !== 3) {
                    setColor("#002fff"); 
                }else{
                    setRetryButton("Birozdan so'ng qayta urinib ko'ring");
                    setColor("red");
                }         
            }   
        }, 1000)

        return () => clearInterval(timer)
    });
    useEffect(() => (
         setPhone(window.localStorage.getItem("phoneUser"))
    ), []);
    const submitHandler = useCallback((value) => {
        setLoading(true);
        if(value.code === "") {
            setError("Kod ni kiriting");
            setLoading(false)
        }else {
            authApi.codeVerify({
                query: {
                    phone: phone,
                    code: value.code
                }
            }).then((res) => {
                    if (res.success === true) {
                        setLoading(false);
                        history.push({pathname: Routes.SignUp3, state:{oldUrl: location.state?.oldUrl}})
                    } else if (res.success === false) {
                        setLoading(false);
                        setError(translate("TITTLE_CODE_VERIFY_ERROR"))
                    }
                    localStorage.setItem("code", res?.data?.code);
                }
            )
        }
    }, [authApi, history, phone, translate]);

    return (
        <>
            <section className="sectionSignUpPage2">
                <div className="container">
                    <Formik initialValues={INITIAL_VALUES} onSubmit={(values) => {submitHandler(values)}}>
                        {({handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mainSignUpPage2">

                                    {/* {
                                        <div className="message" style={{display: isMessageOpen}}>Kod yuborildi</div>
                                    } */}

                                    <div className="signUpPage-input-item2">
                                    <span className="resend-report">Kod siz kiritgan telefon raqamiga yuboriladi!</span>
                                        <InputField
                                            inputClassName="signUpPage-inner-item2"
                                            placeholder="Kodni kiriting"
                                            type="text"
                                            name="code"
                                        />

                                        {retryButton === "Birozdan so'ng qayta urinib ko'ring" ? <div className="timer" style={{flexDirection: "column", alignItems: "center", justifyContent: "space-between", marginRight: 0, margin: "0 auto", padding: "20px 20px 0 20px"}} >
                                            <button style={{color: "white"}} disabled={resendDisabled} onClick={() => {
                                                setMessageOpen("none")
                                               if(counter !== 3) {
                                                setSeconds(59);
                                                setMinutes(1);
                                                setCounter( counter + 1 );
                                                setColor("#a9a9a9")
                                               }
                                            }}>{retryButton}</button>
                                            <span> {minutes} : {(seconds).toString().padStart(2, "0")}</span>
                                        </div> : <div className="timer" >
                                            <button style={{color: color}} disabled={resendDisabled} onClick={() => {
                                               if(counter !== 3) {
                                                setSeconds(60);
                                                setMinutes(1);
                                                setCounter( counter + 1 );
                                                setColor("#a9a9a9")
                                               }
                                                resendHandler()
                                            }}>{retryButton}</button>
                                            <span> {minutes} : {(seconds).toString().padStart(2, "0")}</span>
                                        </div>}


                                        {error && <div className="error-text" >
                                            {error}
                                        </div>}
                                        <button className="signUpPage-btn-inner2" type="submit">
                                            {loading ? <div className="block-loading">
                                                <p className="">Iltimos kuting...</p>
                                            </div> : <span>Davom etish</span>}
                                        </button>
                                        <p className="signUpPage-text-item2">
                                            Akkauntingiz bormi? unda <Link  to={Routes.LoginPage}>bu yerga</Link> bosing
                                        </p>
                                    </div>

                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
}
