import React from 'react';
import "../../../components/css/main.css"
import closeIcon from "../../../components/icons/close.svg";
import {useDispatch} from "react-redux";
import {setFileUrl} from "../../../reducers/appReducer";
import "../../../components/css/header.css";

interface Props {
    readonly fileUrl: string;
}

function FileReader({fileUrl}: Props) {
    const dispatch = useDispatch();
    return (
        <div className="file-reader">
            <div className="position-relative">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                />
                <div className="hide-download"/>
                <div className="img-div" onClick={() => dispatch(setFileUrl({fileUrl: ""}))}>
                    <img src={closeIcon} alt=""/>
                    {/*<button>Yopish</button>*/}
                </div>
                <div className="hide-content"/>
            </div>
        </div>
    );
}

export default FileReader;