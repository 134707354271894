import { I18nSchema } from "./I18nSchema";

export const I18nEnglish: I18nSchema = {
  TITTLE_CODE_VERIFY_ERROR: "",
  TITTLE_LOGIN_ERROR: "",

  // Navbar
  NAVBAR_MAIN_PAGE_TITTLE: "Main page",
  NAVBAR_COURSES_TITTLE: "Courses",
  NAVBAR_FAQ_TITTLE: "FAQ",
  NAVBAR_ABOUT_TITTLE: "About us",
  NAVBAR_EDUBRANDING_TITTLE: "Edu-branding",
  NAVBAR_KABINET_TITLE: "Cabinet",
  NAVBAR_BILIMBERISH_TITLE: "Share knowladge",
  NAVBAR_CHIQISH_TITLE: "Log out",
  NAVBAR_QIDIRUV_TITLE: "Search",

  // ALERT
  ALERT_ASSALOMUALAYKUM: "Hello",
  ALERT_ROYXATDANOTING: "Register Now and",
  ALERT_VAUCHERGAEGABOLING: "get 50,000 UZS as a gift",
  ALERT_ROYXATDANOTISH: "Sign up",
  ALERT_MALUMOTTOLDIRISH: "Add information",

  //404 page
  PAGE_NOT_FOUND_TITTLE: "Oops! Page not found",
  PAGE_NOT_FOUND_FOOTER_TITTLE:
    "We are sorry, but the page you requested was not found",
  NAVBAR_LOGIN_TITTLE: "Login",

  //Main
  TITTLE_MAIN_PLATFORMA: "Online education",
  TITTLE_MAIN_PLATFORM: "Platform",
  TITTLE_MAIN_TALIM: "An online platform for mentors and students",
  TITTLE_MAIN_KURSLAR: " Courses",
  TITTLE_MAIN_VIDEO: "Watch the video",
  TITTLE_MAIN_BILIMOLUVCHI: "Student",
  TITTLE_MAIN_BILIMULASHUVCHI: "Mentor",
  TITTLE_MAIN_PROFIL: "Create an account",
  TITTLE_MAIN_KURSTANGLANG: "Choose a course",
  TITTLE_MAIN_OQISHNI: "Start learning",
  TITTLE_MAIN_TOP: "Top",
  TITTLE_MAIN_DISCOUNT: "Discount",
  TITTLE_MAIN_KURS: "courses",
  TITTLE_MAIN_XARID: "Buy",
  TITTLE_MAIN_BOSHLASH: "Start",
  TITTLE_MAIN_DAVOM: "Continue",
  TITTLE_MAIN_BEPUL: "Free",
  TITTLE_MAIN_SOM: "uzs",
  TITTLE_MAIN_BARCHASI: "All courses",
  TITTLE_MAIN_YANGI: "New",
  TITTLE_MAIN_SPIKER: "Mentors",
  TITTLE_MAIN_DAN: "More than 70 specialists",
  TITTLE_MAIN_DANS: "More than 30 ",
  TITTLE_MAIN_ORTIQMAXSULOT: "",
  TITTLE_MAIN_PROFILS: "profile",
  TITTLE_MAIN_ORTIQSOXA: "professions and",
  NAVBAR_COURSES_TITTLES: "Courses",
  TITTLE_MAIN_MARKETING: "Marketing",
  TITTLE_MAIN_DASTURLASH: "Programming",
  TITTLE_MAIN_DIZAYN: "Design",
  TITTLE_MAIN_DIN: "Religion",
  TITTLE_MAIN_XORIJI: "Foreign languages",
  TITTLE_MAIN_BIZNES: "Business",
  TITTLE_MAIN_MENEJMENT: "Management",
  TITTLE_MAIN_USTUNLIK: "ADVANTAGE",
  TITTLE_MAIN_NEGA: "Why",
  TITTLE_MAIN_AYNAN: "EduOn",
  TITTLE_MAIN_QULAY: "Actual",
  TITTLE_MAIN_PLATFORMADA: "On the platform",
  TITTLE_MAIN_SOAT: "Any time, Platform works 24/7",
  TITTLE_MAIN_ISTALGANJOY: "Everywhere",
  TITTLE_MAIN_INTERNETBOLSA: "Enough Internet, EduOn works everywhere",
  TITTLE_MAIN_MUTAXASISLAR: "Specialists",
  TITTLE_MAIN_DASTURLASHMUTAXASISLAR: "Courses are conducted by specialists",
  TITTLE_MAIN_AKTUAL: "Actual",
  TITTLE_MAIN_BARCHAAQTUAL: "There are classes in all relevant professions",
  TITTLE_MAIN_OSON: "Easy",
  TITTLE_MAIN_SAMARALIILM:
    "All opportunities for efficiently obtaining knowledge",
  TITTLE_MAIN_FIKIRLAR: "OPINIONS",
  TITTLE_MAIN_ULARBIZXAQIMIZDA: "What teachers think",
  TITTLE_MAIN_NIMAFIKIR: "about us",
  TITTLE_MAIN_ONLINETALIM: "Get knowledge online",
  TITTLE_MAIN_PLATFORMAORQALI:
    "Get knowledge from our platform anywhere, and anytime",
  TITTLE_MAIN_ROYXATANOTISH: "Sign up",
  TITTLE_MAIN_AKKAUNTINGIZ: "Do you have an account",
  TITTLE_MAIN_DIYORSH: "Xasan M.",
  TITTLE_MAIN_OQITUVCHI: "Teacher",
  TITTLE_MAIN_PLATFORMAJUDAQULAY:
    "The platform is very comfortable. Improving teaching effectiveness has several functions. Prices are also not expensive",
  TITTLE_MAIN_PLATFORMAJUDAQULAY2:
    "If someone wants to contribute to the field of education Eduon is ready for it. This platform is like Coursera, Udemy platforms which stops ever",
  TITTLE_MAIN_PLATFORMAJUDAQULAY3:
    "Internet and being online opens tons of opportunities for the development of people ",
  TITTLE_MAIN_SHAVKATHASAN: "Muhammadali E.",
  TITTLE_MAIN_HUZAYFAB: "",
  TITTLE_MAIN_XUSAN: "Xusan M.",
  //Main2
  TITTLE_MAIN2_ONLINE: "Online platform",
  TITTLE_MAIN2_PLATFORM: "teaching",
  TITTLE_MAIN2_KELAJAK: "Promote quality education for future youth",
  TITTLE_MAIN2_BILIM: "Knowledge Exchange",
  TITTLE_MAIN2_VIDEO: "Watch the video",
  TITTLE_MAIN2_BILIMOLUVCHI: "Student",
  TITTLE_MAIN2_OQUVCHI: "Student",
  TITTLE_MAIN2_BILIMULASHUVCHI: "Mentor",
  TITTLE_MAIN2_OQITUVCHI: "Teacher",
  TITTLE_MAIN2_PROFIL: "Create am account",
  TITTLE_MAIN2_SOHANGIZ: "Choose your specialization",
  TITTLE_MAIN2_OQITISH: "Start teaching",
  TITTLE_MAIN2_TARIFLAR: "Visits",
  TITTLE_MAIN2_OQUVCHILAR: "Students",
  TITTLE_MAIN2_SOHA: "Professions",
  TITTLE_MAIN2_SOTILGAN: "Sold courses",
  TITTLE_MAIN2_KURSLAR: "Courses",
  TITTLE_MAIN2_QADAMLAR: "STEPS",
  TITTLE_MAIN2_TALIMBERISH: "",
  TITTLE_MAIN2_QANDAY: "How can I",
  TITTLE_MAIN2_BOSHLASH: "start teaching",
  TITTLE_MAIN2_ROYXATDAN: "Register now",
  TITTLE_MAIN2_BILIMULASHUVCHISIFATIDA: "Click here",
  TITTLE_MAIN2_BUYERGA: "to register as a Mentor",
  TITTLE_MAIN2_BOSING: "",
  TITTLE_MAIN2_MALUMOTLAR: "Information",
  TITTLE_MAIN2_PLATFORMATOMONIDAN:
    "Complete all required your data in the platform",
  TITTLE_MAIN2_KURSYUKLASH: "Create a course",
  TITTLE_MAIN2_SOHANGIZBOYICHA: "Start uploading courses about your specialty",
  TITTLE_MAIN2_USTUNLIK: "ADVANTAGE",
  TITTLE_MAIN2_NEGA: "Why",
  TITTLE_MAIN2_AYNAN: "EduOn",
  TITTLE_MAIN2_AUDITORIYA: "Audience",
  TITTLE_MAIN2_OZINGIZSHAXSI: "Gather your own personal audience",
  TITTLE_MAIN2_OQUVCHIMARKAZ: "Training center",
  TITTLE_MAIN2_BEPUL: "Open a free online learning center",
  TITTLE_MAIN2_STATISTIKA: "Statistics",
  TITTLE_MAIN2_BARCHA: "Get all the statistics",
  TITTLE_MAIN2_STATISTIK: "Statistics",
  TITTLE_MAIN2_QOSHIMCHA: "Discover an additional source of income",
  TITTLE_MAIN2_OSON: "Easy",
  TITTLE_MAIN2_BILIMBERISH:
    "An interface with all the conveniences for learning",
  TITTLE_MAIN2_FIKRLAR: "OPINIONS",
  TITTLE_MAIN2_USTOZLAR: "What teachers think ",
  TITTLE_MAIN2_NIMAFIKIRDA: " about us",
  TITTLE_MAIN2_ONLINETALIM: "Get knowledge online",
  TITTLE_MAIN2_PLATFORMAMIZDA:
    "Get knowledge from our platform anywhere, and anytime",
  TITTLE_MAIN2_ROYXAT: "Sign up",
  TITTLE_MAIN2_AKKAUNTINGIZ: "Do you have an account",

  //COURSES
  TITTLE_COURSE_BIZNESK: "Business",
  TITTLE_COURSE_ITK: "IT and Development",
  TITTLE_COURSE_AKADEMIKK: "Academic",
  TITTLE_COURSE_DINK: "Religion",
  TITTLE_COURSE_BOSHQALARK: "Other",
  TITTLE_COURSE_YANGIQ: "New courses",
  TITTLE_COURSE_ESKIQ: "Oldcourses",
  TITTLE_COURSE_REYTINGQ: "By rating",
  TITTLE_COURSE_PULQ: "By price",
  TITTLE_COURSE_BEPULQ: "Free courses",
  TITTLE_COURSE_TOIFA: "Category",
  TITTLE_COURSE_NARX: "Price",
  TITTLE_COURSE_FILTER: "Filter",
  TITTLE_COURSE_XUSHKELIBSIZ: "Welcome",
  TITTLE_COURSE_ILM: "knowledge seeker",
  TITTLE_COURSE_XOSHBUGUN: "What do we learn for today?",
  TITTLE_COURSE_BOLIM: "By Sections",
  TITTLE_COURSE_BOYICHA: "",
  TITTLE_COURSE_USTOZLAR: "By Mentors",
  TITTLE_COURSE_BOYICHAS: "",
  TITTLE_COURSE_BOLIMLAR: "By Sections",
  TITTLE_COURSE_YANGIQOSHISH: "Newcomers",
  TITTLE_COURSE_REYTING: "Rating",
  TITTLE_COURSE_UZBEKCHA: "Uzbek",
  TITTLE_COURSE_RUSCHA: "Russian",
  TITTLE_COURSE_INGLISCHA: "English",
  TITTLE_COURSE_QOZOQCHA: "Kazakh",
  TITTLE_COURSE_TOJIKCHA: "Tajik",
  TITTLE_COURSE_BOLIML: "Sections",
  TITTLE_COURSE_FRONTEND: "Frontend",
  TITTLE_COURSE_BACKEND: "Backend",
  TITTLE_COURSE_MOBILE: "Mobile",
  TITTLE_COURSE_FLUTTER: "Flutter",
  TITTLE_COURSE_SUNIYINTELEKT: "Artificial intelligence",
  TITTLE_COURSE_KORSATISH: "Show",
  TITTLE_COURSE_YANGIQURSLAR: "New courses",
  TITTLE_COURSE_BARCHASI: "All",
  TITTLE_COURSE_MARKETING: "Marketing",
  TITTLE_COURSE_DASTURLASH: "Programming",
  TITTLE_COURSE_BIZNES: "Business",
  TITTLE_COURSE_DINIY: "Religious",
  TITTLE_COURSE_XORIJIYTIL: "Foreign language",
  TITTLE_COURSE_MENEJMENT: "Management",
  TITTLE_COURSE_XARIDQILISH: "Buy",
  TITTLE_COURSE_PROFIL: "Profile",
  TITTLE_COURSE_YANAKORISH: "More",
  TITTLE_COURSE_SOM: "UZS",
  TITTLE_COURSE_YANGI: "New added",
  TITTLE_COURSE_AVALGI: "Old courses",
  TITTLE_COURSE_REYTINGBOYICHA: "By Rating",
  TITTLE_COURSE_NARXBOYICHA: "By Price",
  TITTLE_COURSE_BEPULKURS: "Free courses",
  TITTLE_COURSE_YANGIQOSHILGANLAR: "New additions",
  TITTLE_COURSE_AVVALGIKURSLAR: "Previous courses",
  TITTLE_COURSE_REYTINGBOYICHAS: "According to the rating",
  TITTLE_COURSE_NARXBOYICHAS: "By price",
  TITTLE_COURSE_BEPUL: "Free courses",

  // ID COURSE
  TITTLE_COURSEID_KURSTARKIBI: "Course content",
  TITTLE_COURSEID_OXSHASH: "Mentor and similar courses",
  TITTLE_COURSEID_KURSICHIDA: "In course",
  TITTLE_COURSEID_UMIRBOD: "Lifetime Course Ownership",
  TITTLE_COURSEID_VIDEO: "videos",
  TITTLE_COURSEID_IZOHLAR: "Comments",

  // PAYMENT
  TITTLE_PAYMENT_HISOBIM: "My balance",
  TITTLE_PAYMENT_VAUCHER: "Voucher",
  TITTLE_PAYMENT_BALANS: "Balance",
  TITTLE_PAYMENT_KARTA: "Card",
  TITTLE_PAYMENT_SUMMA: "UZS",
  TITTLE_PAYMENT_YETARLIEMAS:
    "You don't have enough money in your wallet, replenish your wallet",
  TITTLE_PAYMENT_KARTANISAQLAB: "Save Card",
  TITTLE_PAYMENT_KARTAMORQALI: "Pay with my card",
  TITTLE_PAYMENT_TIZIMISHLAMAYAPDI:
    "Currently, payment through this payment system is not possible. Please try again later. Or check the amount on the card",
  TITTLE_PAYMENT_PUL: "Money",
  TITTLE_PAYMENT_BEKORQILISH: "Cancel",
  TITTLE_PAYMENT_TASDIQLASHKODI: "Enter confirmation code",
  TITTLE_PAYMENT_TASDIQLASH: "Confirm",
  TITTLE_PAYMENT_TABRIKLAYMIZ: "Congratulations !!!",
  TITTLE_PAYMENT_KURSSOTIBOLINDI: "Course purchased",

  //FAQ
  TITTLE_FAQ_KOPBERILADIGON: "Giving a lot",
  TITTLE_FAQ_SAVOLARGA: "answers to questions",
  TITTLE_FAQ_SAVOLAR: "To the questions",
  TITTLE_FAQ_JAVOBLAR: "answers",
  TITTLE_FAQ_SIZHAMSAVOLYOLLASHINGIZ:
    "You can also send a question and we will answer within 6 hours",
  TITTLE_FAQ_BILIMOLUVCHI: "Learner",
  TITTLE_FAQ_OQUVCHI: "Reader",
  TITTLE_FAQ_BILIMULASHUVCHI: "Knowledge sharing",
  TITTLE_FAQ_OQTUVCHI: "Teacher",
  TITTLE_FAQ_BILIM: "Learner",
  TITTLE_FAQ_OQUVCH: "The reader",
  TITTLE_FAQ_BARCHASI: "That's all",
  TITTLE_FAQ_TOLOVLAR: "Payments",
  TITTLE_FAQ_KURSLAR: "Courses",
  TITTLE_FAQ_XARIDQILISH: "Shopping",
  TITTLE_FAQ_PROFIL: "Profile",
  TITTLE_FAQ_SHIKOYAT: "Complaint",
  TITTLE_FAQ_BOSHQALAR: "Others",
  TITTLE_FAQ_YANAKORSATISH: "Show more",

  //BLOCK
  TITTLE_BLOCK_ONLINE: "Online",
  TITTLE_BLOCK_TALIM: "learning platform",
  TITTLE_BLOCK_OZBEKISTONDAGI: "The first in Uzbekistan",
  TITTLE_BLOCK_ONLAYNOQUV: "online learning platform",
  TITTLE_BLOCK_TRENINGLARSEMINARLAR:
    "Unique for trainings, seminars, webinars and online tutorials. Uzbek platform. We share the knowledge of successful people.",
  TITTLE_BLOCK_VIDEONIKORISH: "Watch a video",
  TITTLE_BLOCK_EDUON: "Contributors to ",
  TITTLE_BLOCK_HISSA: " EduOn",
  TITTLE_BLOCK_QOSHGANLAR: "",
  TITTLE_BLOCK_BIZHAQIMIZDA: "About Us",
  TITTLE_BLOCK_EDUONMFAQTORTOMONIDAN:
    "EduOn was founded by MFactor with the goal of providing everyone, everywhere, with a life-changing learning experience.",
  TITTLE_BLOCK_HOZIRDABU:
    "It is currently the leading online educational platform enabling millions of students across Uzbekistan to learn new skills.",
  TITTLE_BLOCK_OZBEKISTONNINGDAORTIQ:
    "More than the best universities and teachers of Uzbekistan, together with EduOn, offer courses, specialties, certificates, and diploma programs.",
  TITTLE_BLOCK_KURSHAQIDA: "About course",
  TITTLE_BLOCK_EDUONDAGIHARBIR:
    "Each course at EduOn is taught by instructors from universities and companies around the world, so you can learn new things whenever and wherever you want",
  TITTLE_BLOCK_YUZLABKURSLARTALABA:
    "Hundreds of courses provide access to on-demand video lectures, homework exercises, and public discussion forums. Discover something new for yourself every day",
  TITTLE_BLOCK_VIDEOKORISH: "Watch a video",
  TITTLE_BLOCK_SHOTARUSTAVELI: "Shota Rustaveli 6th house",
  TITTLE_BLOCK_ISMINGIZ: "Your name",
  TITTLE_BLOCK_ELEKTRONPOCHTANGOZ: "Your email",
  TITTLE_BLOCK_TELEFONRAQAMINGIZ: "Your phone number",
  TITTLE_BLOCK_XABARINGIZ: "Your message",
  TITTLE_BLOCK_YUBORISH: "Send",
  TITTLE_BLOCK_HAMKORLARIMIZ: "PARTNERS",

  //FOOTERS
  TITTLE_FOOTERS_OQUVCHI: "Student",
  TITTLE_FOOTERS_SPIKER: "Mentor",
  TITTLE_FOOTERS_VEBSAYT: "Website",
  TITTLE_FOOTERS_BOSHSAHIFA: "Home ",
  TITTLE_FOOTERS_KURSLAR: "Modules",
  TITTLE_FOOTERS_FAQ: "Support",
  TITTLE_FOOTERS_MALUMOTLAR: "Information",
  TITTLE_FOOTERS_BIZHAQIMIZDA: "About us",
  TITTLE_FOOTERS_FOYDALANISH: "Terms of use",
  TITTLE_FOOTERS_MAXFIYLIK: "Privacy and Policy",
  TITTLE_FOOTERS_MANZILLAR: "Addresses",
  TITTLE_FOOTERS_TOSHKENTSH: "Namangan shahar, K.Otamirzayev,",
  TITTLE_FOOTERS_RUSTAVELI: "Rustaveli 1st narrow street, 6th house",
  TITTLE_FOOTERS_BARCHA: "All rights reserved",

  // Validations
  FieldErrorRequired: "This field is required",
  FieldPasswordErrorShort: "Password length must be at least 6 characters",

  //SEARCH PAGE
  TITTLE_SEARCH_QIDIRUVNATIJASI: "Search results",
  TITTLE_SEARCH_TOPILMADI: "Courses not found",
  TITTLE_SEARCH_SPIKERTOPILMADI: "Speaker not found",

  //LOGIN
  LOGIN_FROM_PHONRE: "By phone",
  LOGIN_PHONRE: "Phone",
  LOGIN_FROM_MAIL: "By email",
  LOGIN_MAIL: "Email",
  LOGON_INPUT_EMAIL_PLACEHOLDER: "Enter your email",
  LOGIN_TITTLE_FORGET_PSW: "Forget your password",
  LOGIN_TITTLE_REGISTER: "Register",
  LOGIN_TITTLE_LOGIN_LOADING: "Loading...",
  LOGIN_TITTLE_LOGIN: "Login",
};
