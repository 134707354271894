import "./footer.scss";
import {
  AiOutlineAppstore,
  AiTwotoneThunderbolt,
  AiOutlinePauseCircle,
} from "react-icons/ai";
import { GiBlackBook } from "react-icons/gi";
import { BsFillChatLeftFill } from "react-icons/bs";
import { MdHexagon } from "react-icons/md";
import TimerNew from "../Tests/TimerNew";

interface Props {
  readonly timer?: boolean;
}

const Footer = ({ timer }: Props) => {
  // console.log(timer);
  return (
    <div className="footer-part">
      <ul className="footer-part__list">
        <li className="footer-part__item">
          <span>Time remaining : {timer ? "00:00:00" : <TimerNew />}</span>
          <AiOutlineAppstore size={25} />
        </li>
        <ul className="footer-part-list">
          <li className="footer-part-list__item">
            <GiBlackBook size={20} />
            <span>My Notebook</span>
          </li>
          <li className="footer-part-list__item">
            <AiTwotoneThunderbolt size={20} />
            <span>Flashcards</span>
          </li>
          <li className="footer-part-list__item">
            <BsFillChatLeftFill size={20} />
            <span>My Notebook</span>
          </li>
          <li className="footer-part-list__item">
            <AiOutlinePauseCircle size={20} />
            <span>Flashcards</span>
          </li>
          <li className="footer-part-list__item">
            <MdHexagon size={20} />
            <span>Flashcards</span>
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default Footer;
