import {SWRConfig} from "swr";
import React, {ReactNode, useCallback} from "react";

import {ApiProvider} from "../api/ApiContext";
import {I18nProvider} from "../i18n/I18nContext";
import {useShallowEqualSelector} from "../hooks/useShallowSelector";
import {appLanguageSelector} from "../reducers/appReducer";
import {refreshTokenSelector, resetToken, tokenSelector} from "../reducers/authReducer";
import {clearUser} from "../reducers/userReducer";
import {Routes} from "../constants/Routes";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import Cookies from "universal-cookie/cjs";

interface Props {
  readonly children: ReactNode;
}

export function ProviderContainer({ children }: Props) {
  const language = useShallowEqualSelector(appLanguageSelector);
  const token = useShallowEqualSelector(tokenSelector);
  const refresh = useShallowEqualSelector(refreshTokenSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const cookie = new Cookies();

    const logout = useCallback(() => {
        localStorage.removeItem('myCourses');
        dispatch(clearUser());
        dispatch(resetToken());
        cookie.remove("token", {path: "/"});
        cookie.remove("refreshToken", {path: "/"});
        history?.push(Routes.LoginPage)
    }, [dispatch, history, cookie]);
  return (
    <I18nProvider data={{ language }}>
      <ApiProvider data={{ token, language, refresh, logout }}>
            <SWRConfig value={{ revalidateOnFocus: false }}>{children}</SWRConfig>
      </ApiProvider>
    </I18nProvider>
  );
}
