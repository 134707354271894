import React from "react";
import closeIcon from "../../../components/icons/close.svg";
import { setPdfUrl } from "../../../reducers/appReducer";
import { useDispatch } from "react-redux";
import "../../../components/css/header.css";
import "../../../components/css/main.css";
import { GrClose } from "react-icons/gr";
import { useHistory } from "react-router";

interface Props {
  readonly fileUrl: string;
}

function PdfReader({ fileUrl }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClose = () => {
    let localCurrentVideo =
      localStorage.getItem("videoId") === null
        ? 30
        : Number(localStorage.getItem("videoId"));
    console.log(localStorage.getItem("videoId") === null);

    let videoId = localCurrentVideo === 0 ? 30 : localCurrentVideo;
    history.push(`/course-videos/${videoId}`);
  };

  return (
    <div
      className="pdf-reader"
      style={{ width: "100%", height: "100% !important", overflow: "hidden" }}
    >
      <iframe
        src={fileUrl}
        title="title"
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: "800px",
          position: "absolute",
          zIndex: 999,
          top: 0,
          bottom: 0,
        }}
      />
      <div className="hide-download-pdf" />
      <div
        style={{
          position: "fixed",
          right: "50px",
          top: "80px",
          zIndex: 1011,
          padding: "10px",
          backgroundColor: "#FFF",
          display: "flex",
          cursor: "pointer",
          borderRadius: "3px",
        }}
        onClick={() => onClose()}
      >
        <GrClose />
        {/* <img src={closeIcon} alt="" style={{ width: "50px !important" }} /> */}
        {/*<button>Yopish</button>*/}
      </div>
    </div>
  );
}

export default PdfReader;
