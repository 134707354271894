import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import '../../components/css/SignUpPage.css';
import { useAuthContext } from '../../api/auth/authContext';
import { Routes } from '../../constants/Routes';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const INITIAL_VALUES = {
  phone: '',
};

interface Props {
  readonly switchUser: boolean;
  readonly setSwitchUser: Dispatch<SetStateAction<boolean>>;
}

export default function SignUpPage({ setSwitchUser, switchUser }: Props) {
  const location:any = useLocation()
  const { authApi } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<any>('');
  const [speaker, setSpeaker] = useState(true);
  const [error, setError] = useState('');
  const history = useHistory();
  const url = window?.location?.href?.split('/');

  const submitHandler = useCallback(() => {
    setLoading(true);
    if (phone.substr(0, 3) === '998' && phone.length < 12) {
      setError("Telefon raqamni to'gri kiriting");
      setLoading(false);
    } else {
      let phoneNumber = phone.replace(/\s/g, '');
      phoneNumber =
        phoneNumber.length === 9
          ? '998' + phoneNumber
          : phoneNumber.includes('+')
          ? phoneNumber.replace('+', '')
          : phoneNumber;
      phoneNumber = phoneNumber.substr(0, 25);
      localStorage.setItem('phoneUser', phoneNumber);
      if (url[5]) {
        localStorage.setItem('ref', url[5]);
      }
      authApi
        .loginCredentials({
          query: {
            phone: phoneNumber,
            type: 'registeration',
          },
        })
        .then((res) => {
          if (res.message === 'Code yuborildi!') {
            setLoading(false);
            history.push({ pathname: Routes.SignUp2, state:{oldUrl: location.state?.oldUrl} });
          } else if (res.success === false) {
            setLoading(false);
            setError(
              "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring."
            );
          }
        })
        .catch(() => {
          setLoading(false);
          setError(
            "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring."
          );
        });
    }
  }, [authApi, history, phone]);
  const submitHandlerEmail = useCallback(() => {
    setLoading(true);
    localStorage.setItem('phoneUser', phone);
    if (url[5]) {
      localStorage.setItem('ref', url[5]);
    }
    authApi
      .loginCredentials({
        query: {
          phone: phone,
          type: 'registeration',
        },
      })
      .then((res) => {
        if (res.message === 'Code yuborildi!') {
          setLoading(false);
          history.push({
            pathname: Routes.SignUp2,
            state: { oldUrl: location.state?.oldUrl },
          });
        } else if (res.success === false) {
          setLoading(false);
          setError(
            "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring."
          );
        }
      })
      .catch(() => {
        setLoading(false);
        setError(
          "Bunday foydalanuvchi avval ro'yxatdan o'tgan! Iltimos tekshirib qaytadan kiriting yoki kirish orqali kiring."
        );
      });
  }, [history, phone, authApi]);
  return (
    <>
      <section className="sectionSignUpPage">
        <div className="container">
          <div className="mainSignUpPage">
            <div className="signUpPage-inner">
              {switchUser ? (
                <div className="signin-courses_sec_btn ">
                  <h2 className="course_btn_active bilim-oluvchi">
                    Telefon orqali
                  </h2>
                  <h3 className="course_btn_active blim-ulashuvchi">Telefon</h3>
                  <button
                    onClick={() => setSwitchUser((prevState) => !prevState)}
                    className="course_btn_1"
                    type="button"
                  >
                    <div></div>
                  </button>
                  <h2 className="course_btn_pass bilim-oluvchi">
                    Email orqali
                  </h2>
                  <h3 className="course_btn_pass blim-ulashuvchi">Email</h3>
                </div>
              ) : (
                <div className="signin-courses_sec_btn">
                  <h2 className="course_btn_pass bilim-oluvchi">
                    Telefon orqali{' '}
                  </h2>
                  <h3 className="course_btn_pass blim-ulashuvchi">Telefon</h3>
                  <button
                    onClick={() => setSwitchUser((prevState) => !prevState)}
                    className="course_btn_2"
                    type="button"
                  >
                    <div></div>
                  </button>
                  <h2 className="course_btn_active bilim-oluvchi">
                    Email orqali
                  </h2>
                  <h3 className="course_btn_active blim-ulashuvchi">Email</h3>
                </div>
              )}
            </div>
            <div className="signUpPage-input-item">
              {switchUser ? (
                <PhoneInput
                  containerClass="signUpPage-inner-item phone-flag"
                  country={'uz'}
                  // value={phone}
                  masks={{ uz: '(..) ...-..-..' }}
                  onChange={(e) => setPhone(e)}
                />
              ) : (
                <input
                  className="loginPage-inner-item email-input"
                  type="email"
                  placeholder="Emailni kiriting"
                  onChange={(e) => setPhone(e.target.value)}
                />
              )}
              {error && <div className="error-text reg_error">{error}</div>}
              <button
                className="signUpPage-btn-inner"
                type="submit"
                onClick={switchUser ? submitHandler : submitHandlerEmail}
              >
                {loading ? (
                  <div className="block-loading">
                    <p className="mt-2">Iltimos kuting...</p>
                  </div>
                ) : error ? (
                  <span>Qaytadan kiritish</span>
                ) : (
                  <span>Davom etish</span>
                )}
              </button>
              <p className="signUpPage-text-item">
                Akkauntingiz bormi? unda{' '}
                <Link to={Routes.LoginPage}>bu yerga</Link> bosing
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
